import React from 'react';
import { translations } from 'src/constants/translations';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  IFieldWithTranslation,
  IMarketplaceFilterLocationFilter,
  IMarketplaceProperty, sortOrderOptions, sortOrderOptionsMap, SortOrderType,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  useStyles,
} from './styles';

interface LocationSubGroupLocationGroupProps {
  entity: {item: IMarketplaceFilterLocationFilter},
  lang: string,
  locationOptions: IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]
}

export const LocationSubGroupLocationGroup: React.FC<LocationSubGroupLocationGroupProps> = ({ entity, lang, locationOptions }) => {
  const styles = useStyles();
  return (
    <div className={styles.locationsSubGroupFields}>
      <BoxFormTextField
        label={translations.name}
        name="name"
        defaultValue={entity.item.name?.[lang]}
        onChange={(event) => {
          setInputValue<IMarketplaceFilterLocationFilter>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
        }}
      />
      <BoxFormAutocomplete
        label={translations.selectContainer}
        name="container"
        defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
        onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
          const tmp = entity;
          tmp.item.property = event.value;
        }}
        options={locationOptions}
      />
      <BoxFormAutocomplete
        label={translations.containerSortOrder}
        name="containerSortOrder"
        defaultValue={
          entity.item.propertyValuesOrder
            ? {
              label: sortOrderOptionsMap[entity.item.propertyValuesOrder],
              value: entity.item.propertyValuesOrder,
            }
            : undefined
        }
        options={sortOrderOptions}
        onChange={(event: IDevelopmentSpecificationPropChange<SortOrderType>) => {
          const tmp = entity;
          tmp.item.propertyValuesOrder = event.value;
        }}
      />
      <BoxFormAutocomplete
        label={translations.selectElement}
        name="element"
        defaultValue={{ label: entity.item.subFilters[0].property?.translates?.[lang] || entity.item.subFilters[0].property?.systemName, value: entity.item.subFilters[0].property }}
        onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
          const tmp = entity;
          tmp.item.subFilters[0].property = event.value;
        }}
        options={locationOptions}
      />
      <BoxFormAutocomplete
        label={translations.elementSortOrder}
        name="elementSortOrder"
        defaultValue={
          entity.item.propertyValuesOrder
            ? {
              label: sortOrderOptionsMap[entity.item.propertyValuesOrder],
              value: entity.item.propertyValuesOrder,
            }
            : undefined
        }
        options={sortOrderOptions}
        onChange={(event: IDevelopmentSpecificationPropChange<SortOrderType>) => {
          const tmp = entity;
          tmp.item.propertyValuesOrder = event.value;
        }}
      />
    </div>
  );
};
