import React, { useState, useRef, useMemo } from 'react';
import { NavigationTabs } from 'src/components/Marketplace/components/NavigationTabs';
import { CollapsibleFilter } from 'src/components/Marketplace/components/MarketplaceFilterOptions/CollapsibleFilter';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import { LocationFilterPopup } from 'src/components/Marketplace/components/LocationFilter/LocationFilterPopup';
import { TypeFilterPopup } from 'src/components/Marketplace/components/TypeFilter/TypeFilterPopup';
import { PriceFilterPopup } from 'src/components/Marketplace/components/PriceFilter/PriceFilterPopup';
import { RoomsFilterPopup } from 'src/components/Marketplace/components/RoomsFilter/RoomsFilterPopup';
import { FilterOptionSlider } from 'src/components/Marketplace/components/MarketplaceFilterOptions/FilterOptionSlider';
import {
  IMarketplaceFilterCheckboxAllValues,
  IMarketplaceFilterLocation, IMarketplaceFilterRange,
  IMarketplaceFilterTagAllValues, IMarketplaceFilterTagSelectedValues,
  IMarketplaceFilterTagSelectedValueSelectedProperty, IMarketplaceFilterType,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import {
  getCurrentMarketplace, getMarketplaceComplexesTile,
  getMarketplaceInitialFilters, getMarketplaceLanguage, getMarketplacePlacementsTile,
  getMarketplacePrimarySectionTag,
} from 'src/store/marketplace/selectors';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import _ from 'lodash';
import { useStyles } from './styles';

import { ReactComponent as Filtre } from './icons/filtre.svg';

export const MarketplaceFilterOptions = () => {
  const styles = useStyles();
  const [isPopupOpen, setIsPopupOpen] = useState< null | boolean>(false);
  const [activeOptions, setActiveOptions] = useState<IMarketplaceFilterTagSelectedValueSelectedProperty[]>([]);
  // const [priceRange, setPriceRange] = useState([100000, 888888]);
  // const [pricePerSqm, setPricePerSqm] = useState([100000, 888888]);
  // const [totalArea, setTotalArea] = useState([20.2, 8888.88]);
  // const [floor, setFloor] = useState([1, 88]);
  // const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string | undefined>();
  // const [activeButtons, setActiveButtons] = useState<string[]>([]);

  const [activeStations, setActiveStations] = useState({});

  // const [activeClasses, setActiveClasses] = useState([]);
  // const [activeProjects, setActiveProjects] = useState([]);
  // const [activeBuildings, setActiveBuildings] = useState([]);
  // const [activeSections, setActiveSections] = useState([]);
  //
  // const [activeStages, setActiveStages] = useState([]);
  // const [activeDates, setActiveDates] = useState([]);
  // const [activeFinishes, setActiveFinishes] = useState([]);
  //
  // const [activeLevels, setActiveLevels] = useState([]);
  // const [activeTerms, setActiveTerms] = useState([]);
  // const [activeStatus, setActiveStatus] = useState([]);
  // const [activeBenefits, setActiveBenefits] = useState([]);
  const [currency, setCurrency] = useState('UAH');

  const popupOverlayRef = useRef(null);
  const popupContentRef = useRef(null);

  const marketplace = useSelector(getCurrentMarketplace);
  // const marketplaceInitialFilters = useSelector(getMarketplaceInitialFilters);
  const tag = useSelector(getMarketplacePrimarySectionTag);
  const lang = useSelector(getMarketplaceLanguage);
  const complexes = useSelector(getMarketplaceComplexesTile);
  const { setWhereConditions, whereConditions, resetAllFilterWhereConditions } = useMarketPlaceFilter();

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    if (whereConditions) {
      const propertyNames = whereConditions.map((item) => item.propertyName);
      count = _.uniq(propertyNames).length;
    }
    return count;
  }, [marketplace, whereConditions]);

  const handleOptionClick = (prop: IMarketplaceFilterTagSelectedValueSelectedProperty) => {
    if (marketplace) {
      setActiveOptions(
        activeOptions.includes(prop)
          ? activeOptions.filter((opt) => opt.value !== prop.value)
          : [...activeOptions, prop],
      );
      if (marketplace && prop.property) {
        setWhereConditions([{
          propertyName: prop.property?.systemName,
          equals: prop.value[marketplace.language],
        }]);
      }
    }
  };

  const handleTabClick = (tabIndex: string) => {
    setActiveTab(tabIndex);
  };

  const handleCurrencyToggle = (newCurrency: string) => {
    setCurrency(newCurrency);
  };

  const onReset = () => {
    resetAllFilterWhereConditions();
  };

  // useGlobalStyles();

  // You can add class .showTabs for filterOptionsContainer

  return (
    <div className={styles.filterOptionsContainer}>
      <FilterOptionSlider
        tag={tag}
        activeOptions={activeOptions}
        onOptionClick={handleOptionClick}
        styles={styles}
      />

      <div className={styles.allFiltersButtonTitle}>
        Пошук усіх проєктів
      </div>

      <div
        className={['allFiltersTabsTop'].join(
          ' ',
        )}
      >
        <NavigationTabs
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      </div>

      <button className={styles.allFiltersButton} onClick={togglePopup} type="button">
        <span className="icon">
          <Filtre />
        </span>
        <span className={styles.allFiltersButtonDesktop}>
          Всі фільтри
        </span>
        <span className={styles.allFiltersButtonMobile}>Фільтри</span>
        {appliedFiltersCount !== 0 && (
          <span className={styles.allFilters}>{appliedFiltersCount}</span>
        )}
      </button>

      {isPopupOpen && (
        <Box
          ref={popupOverlayRef}
          className={styles.popupOverlay}
          onClick={(e) => {
            if (e.target === popupOverlayRef.current) {
              setIsPopupOpen(false);
            }
          }}
        >
          <div ref={popupContentRef} className={styles.popupContent}>
            <h2 className={styles.popupContentTitle}>
              Всі фільтри
            </h2>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              className={styles.popupContentClose}
              onClick={(e) => {
                e.stopPropagation();
                setIsPopupOpen(false);
              }}
              type="button"
            />

            <div className={styles.popupContentInner}>

              {marketplace && marketplace.frontConfiguration.module.filtersConfiguration.primarySection.selections
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((filter) => (
                  <>
                    { filter.type === IMarketplaceFilterType.GroupingFilter && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <LocationFilterPopup
                          selection={filter as IMarketplaceFilterLocation}
                          isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // activeTab={(filter as IMarketplaceFilterLocation).filters[0].property?.systemName}
                          // setActiveTab={setActiveTab}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          showActions={false}
                          activeStations={activeStations}
                          setActiveStations={setActiveStations}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesTag && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <TypeFilterPopup
                          selection={filter as IMarketplaceFilterTagAllValues}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          onApply={() => setIsPopupOpen(false)}
                          customClass={styles.customPopup}
                          showActions={false}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesRange && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        showSwitcher={false}
                        currency={currency}
                        onCurrencyToggle={handleCurrencyToggle}
                        accordionState={filter.accordionState}
                      >
                        <PriceFilterPopup
                          selection={filter as IMarketplaceFilterRange}
                          isPopupOpen={isPopupOpen}
                          setIsPopupOpen={setIsPopupOpen}
                          showActions={false}
                          customClass={styles.customPopup}
                          labelFrom={(filter as IMarketplaceFilterRange).fromName?.[marketplace.language] || ''}
                          labelTo={(filter as IMarketplaceFilterRange).toName?.[marketplace.language] || ''}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesCheckbox && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <RoomsFilterPopup
                          selection={filter as IMarketplaceFilterCheckboxAllValues}
                          // isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // selectedRooms={selectedRooms}
                          // setSelectedRooms={setSelectedRooms}
                          renderType="button"
                          showActions={false}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                  </>
                ))}
              {marketplace && marketplace.frontConfiguration.module.filtersConfiguration.secondaries
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((filter) => (
                  <>
                    { filter.type === IMarketplaceFilterType.GroupingFilter && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <LocationFilterPopup
                          selection={filter as IMarketplaceFilterLocation}
                          isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // activeTab={activeTab}
                          // setActiveTab={setActiveTab}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          showActions={false}
                          activeStations={activeStations}
                          setActiveStations={setActiveStations}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesTag && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <TypeFilterPopup
                          selection={filter as IMarketplaceFilterTagAllValues}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          onApply={() => setIsPopupOpen(false)}
                          customClass={styles.customPopup}
                          showActions={false}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesRange && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        showSwitcher={false}
                        currency={currency}
                        onCurrencyToggle={handleCurrencyToggle}
                        accordionState={filter.accordionState}
                      >
                        <PriceFilterPopup
                          selection={filter as IMarketplaceFilterRange}
                          isPopupOpen={isPopupOpen}
                          setIsPopupOpen={setIsPopupOpen}
                          showActions={false}
                          customClass={styles.customPopup}
                          labelFrom={(filter as IMarketplaceFilterRange).fromName?.[marketplace.language] || ''}
                          labelTo={(filter as IMarketplaceFilterRange).toName?.[marketplace.language] || ''}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesCheckbox && (
                      <CollapsibleFilter
                        title={filter.name?.[marketplace.language] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <RoomsFilterPopup
                          selection={filter as IMarketplaceFilterCheckboxAllValues}
                          // isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // selectedRooms={selectedRooms}
                          // setSelectedRooms={setSelectedRooms}
                          renderType="button"
                          showActions={false}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                  </>
                ))}

              {marketplace && lang && tag?.selectedProperties && (
                <CollapsibleFilter
                  title={tag.name?.[lang] || ''}
                  classes={styles}
                  accordionState={tag.accordionState}
                >
                  <FilterOptionSlider
                    tag={tag}
                    activeOptions={activeOptions}
                    onOptionClick={handleOptionClick}
                    styles={styles}
                    view="list"
                  />
                </CollapsibleFilter>
              ) }

              {/* <CollapsibleFilter */}
              {/*  title="Локація" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <LocationFilterPopup */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    activeTab={activeTab} */}
              {/*    setActiveTab={setActiveTab} */}
              {/*    activeButtons={activeButtons} */}
              {/*    setActiveButtons={setActiveButtons} */}
              {/*    activeStations={activeStations} */}
              {/*    setActiveStations={setActiveStations} */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Тип нерухомості" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <TypeFilterPopup */}
              {/*    // selection={selection as IMarketplaceFilterTagAllValues} */}
              {/*    activeButtons={activeButtons} */}
              {/*    setActiveButtons={setActiveButtons} */}
              {/*    onApply={() => setIsPopupOpen(false)} */}
              {/*    customClass={styles.customPopup} */}
              {/*    showActions={false} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Вартість, грн" */}
              {/*  classes={styles} */}
              {/*  showSwitcher */}
              {/*  currency={currency} */}
              {/*  onCurrencyToggle={handleCurrencyToggle} */}
              {/* > */}
              {/*  <PriceFilterPopup */}
              {/*    value={priceRange} */}
              {/*    setValue={setPriceRange} */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*    min={100000} */}
              {/*    max={888888} */}
              {/*    labelFrom="від, грн" */}
              {/*    labelTo="до, грн" */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Кімнатність" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <RoomsFilterPopup */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    selectedRooms={selectedRooms} */}
              {/*    setSelectedRooms={setSelectedRooms} */}
              {/*    renderType="button" */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Вартість за м², грн" */}
              {/*  classes={styles} */}
              {/*  showSwitcher */}
              {/*  currency={currency} */}
              {/*  onCurrencyToggle={handleCurrencyToggle} */}
              {/* > */}
              {/*  <PriceFilterPopup */}
              {/*    value={pricePerSqm} */}
              {/*    setValue={setPricePerSqm} */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*    min={100000} */}
              {/*    max={888888} */}
              {/*    labelFrom="від, грн" */}
              {/*    labelTo="до, грн" */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Загальна площа, м²" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <PriceFilterPopup */}
              {/*    value={totalArea} */}
              {/*    setValue={setTotalArea} */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*    min={20.2} */}
              {/*    max={8888.88} */}
              {/*    labelFrom="від, м²" */}
              {/*    labelTo="до, м²" */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter title="Поверх" classes={styles}> */}
              {/*  <PriceFilterPopup */}
              {/*    value={floor} */}
              {/*    setValue={setFloor} */}
              {/*    isPopupOpen={isPopupOpen} */}
              {/*    setIsPopupOpen={setIsPopupOpen} */}
              {/*    showActions={false} */}
              {/*    customClass={styles.customPopup} */}
              {/*    min={1} */}
              {/*    max={88} */}
              {/*    labelFrom="від" */}
              {/*    labelTo="до" */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* New */}
              {/* <CollapsibleFilter */}
              {/*  title="Клас проєкту" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <ClassFilterPopup */}
              {/*    activeClasses={activeClasses} */}
              {/*    setActiveClasses={setActiveClasses} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Назва проєкту" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <ProjectNameFilterPopup */}
              {/*    activeProjects={activeProjects} */}
              {/*    setActiveProjects={setActiveProjects} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Будинок" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <BuildingFilterPopup */}
              {/*    activeBuildings={activeBuildings} */}
              {/*    setActiveBuildings={setActiveBuildings} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}
              {/* <CollapsibleFilter */}
              {/*  title="Секція/Вежа" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <SectionFilterPopup */}
              {/*    activeSections={activeSections} */}
              {/*    setActiveSections={setActiveSections} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Етап будівництва" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <ConstructionStagePopup */}
              {/*    activeStages={activeStages} */}
              {/*    setActiveStages={setActiveStages} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Завершення будівництва" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <CompletionDatePopup */}
              {/*    activeDates={activeDates} */}
              {/*    setActiveDates={setActiveDates} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Стан оздоблення" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <FinishingStagePopup */}
              {/*    activeFinishes={activeFinishes} */}
              {/*    setActiveFinishes={setActiveFinishes} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Рівневість" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <LevelsPopup */}
              {/*    activeLevels={activeLevels} */}
              {/*    setActiveLevels={setActiveLevels} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Умови купівлі" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <PurchaseTermsPopup */}
              {/*    activeTerms={activeTerms} */}
              {/*    setActiveTerms={setActiveTerms} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/*  title="Статус продажу" */}
              {/*  classes={styles} */}
              {/* > */}
              {/*  <SalesStatusPopup */}
              {/*    activeStatus={activeStatus} */}
              {/*    setActiveStatus={setActiveStatus} */}
              {/*  /> */}
              {/* </CollapsibleFilter> */}

              {/* <CollapsibleFilter */}
              {/* title="Додаткові переваги" */}
              {/* classes={styles} */}
              {/* > */}
              {/* <AdditionalBenefitsPopup */}
              {/*   activeBenefits={activeBenefits} */}
              {/*   setActiveBenefits={setActiveBenefits} */}
              {/* /> */}
              {/* </CollapsibleFilter> */}
            </div>
            <FilterActions
              applyTitle={`Показати (${complexes?.length} проєкти)`}
              onApply={() => setIsPopupOpen(false)}
              onReset={() => onReset()}
            />
          </div>
        </Box>
      )}
    </div>
  );
};
