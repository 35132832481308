import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'relative',
  },
  title: {
    margin: '0 0 8px 0',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1E1E1E',
    fontWeight: '500',
  },
  subtitle: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1E1E1EB2',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 'inherit',
    width: '100%',
  },

  activeSubtitle: {
    color: '#1E1E1E',
  },

  popupTrigger: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  lContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '8px',
    '& .checkboxesWrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      flexWrap: 'wrap',
      gap: '8px',
    },

    '& .btnWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '8px',
    },
  },
  popup: {
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    width: 'calc(200% + 5px)',
    background: 'white',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12)',
    border: '1px solid rgba(30, 30, 30, 0.1);',
    borderRadius: '8px',
    padding: '30px',
    zIndex: 10,
  },

  tabs: {
    display: 'flex',
    backgroundColor: '#1E1E1E0D',
    marginBottom: '20px',
    borderRadius: '50px',
    padding: '4px',
  },
  tab: {
    flex: 1,
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '52px',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '#1E1E1E',
    borderRadius: '50px',
    border: '1px solid transparent',
    transition: 'background 0.3s',

    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      padding: '12px 0',
      height: '44px',
    },

    '&:hover': {
      background: '#1E1E1E0D',
      transition: 'background 0.3s',
    },
  },
  activeTab: {
    background: '#fff',
    border: '1px solid #1E1E1E1A',
    transition: 'border 0.3s',

    '&:hover': {
      border: '1px solid #1E1E1E73',
      transition: 'border 0.3s',
      background: '#fff',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },

  checkboxGroup: {
    marginBottom: '12px',
  },
  checkboxLabel: {
    fontWeight: '500',
    marginBottom: '8px',
  },
  stationList: {
    listStyle: 'none',
    padding: '0!important',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '12px!important',
  },

  checkbox: {
    appearance: 'none',
    width: '20px',
    height: '20px',
    border: '2px solid #1E1E1E4D',
    borderRadius: '2px',
    margin: 0,
    cursor: 'pointer',
    position: 'relative',
    background: '#fff',
    transition: 'all 0.3s ease',

    '&:checked': {
      backgroundColor: '#ED1C24',
      borderColor: '#ED1C24',
    },

    '&:checked::after': {
      content: '""',
      position: 'absolute',
      inset: '-5px 0 0 0',
      margin: 'auto',
      width: '5px',
      height: '10px',
      border: 'solid white',
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
    },

    '& + label': {
      cursor: 'pointer',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },

  checkboxDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    [theme.breakpoints.down('md')]: {
      gap: '15px',
    },
  },
}));
