import { rootUrl } from 'src/constants';

const MADE_BY_TEXT = 'made by www.flat.show';

const headScripts = [
  `${rootUrl}/web/js/pdfAssets/js/tmpl.min.js`,
  // 'https://dev1.flat.show/web/js/pdfAssets/js/jspdf.umd.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/js/html2canvas.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/js/dompurify.2.2.0.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/Arial-normal.js',

  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans-normal.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans-Bold-bold.js',
];

const bodyScript = `
<div style="background-color: {%=o.backgroundColor%};" id="pdf" class="{%= o.placementExplicationData ? 'with-explication' : '' %}">
  <div class="currentDate">{%=o.currentDate%}</div>
  <table id="headerStyle" class="header">
    <tr>
      <td height="80" align="center" valign="bottom" style="padding-top: 10px; display: flex; justify-content: flex-start; min-width: 180px;">
        <div id="pdfLogo"><img class="pdfLogoImg" src={%=o.clientLogoURL%} alt=""></div>
      </td>
      <td width="20" style="min-width: 20px;"></td>
      <td height="80" valign="bottom" style="padding-right: 10px; width: 100%;min-width: 280px;max-width: 280px;">
        <table>
          <tr>
              <th>{%=o.complexAddressFieldName%}</th>
              {% if (o.phones.length >= 1) { %}
                  <th>{%=o.salesDepartmentFieldName%}</th>
              {% } %}
          </tr>
          <tr>
              <td style="padding-right: 40px;" valign="bottom">{%=o.complexAddress%}</td>
              {% if (o.phones.length >= 1) { %}
                  <td>
                      {% for (var i=0; i<o.phones.length; i++) { %}
                          {%=o.phones[i]%}<br>
                      {% } %}
                  </td>
              {% } %}
          </tr>
        </table>
      </td>
      {% if (o.qrCode && o.isShowFlatshow) { %}
        <td class="qr-code-tr">
            <div class="qr-code-wrapper">
                <img class="qr-code-img" src={%=o.qrCode%} alt="">
                <div class="qr-code-link-wrapper">
                    <div class="qr-code-link">scan or click</div>
                </div>
            </div>
        </td>
      {% } %}
    </tr>
  </table>
  <div id="mainImage">
    {% if (o.firstImageURL) { %}
      <img src={%=o.firstImageURL%} alt="">
    {% } %}
  </div>
  {% if (o.placementExplicationData) { %}
    <strong style="background-color: {%=o.backgroundColor%};" class="explicationTitle">{%=o.placementExplicationData.explicationTitle%}</strong>
    <ul id="explicationList">
      {% for (var exp of o.placementExplicationData.explications) { %}
        <li>{%=exp.title%}<span>{%=exp.area%} {%=o.placementExplicationData.explicationSuffix%}</span></li>
      {% } %}
    </ul>
  {% } else { %}
      <table id="visualization">
          <tr>
              <td align="center" valign="middle" id="secondImage">
                  {% if (o.secondImageURL) { %}
                      <img src={%=o.secondImageURL%} alt="">
                  {% } %}
              </td>
          </tr>
      </table>
  {% } %}
  <table id="dataBox">
      <tr>
          <td width="100" align="center" valign="middle" id="compass">
              {% if (o.thirdImageURL) { %}
                  <img src={%=o.thirdImageURL%} alt="">
              {% } %}
          </td>
          <td width="20"></td>
          <td>
              <div id="dataTable">
                  {% for (const [key, value] of Object.entries(o.placementData)) { %}
                      <div><span>{%=key%}</span><strong>{%=value%}</strong></div>
                  {% } %}
              </div>
          </td>
      </tr>
  </table>
  <table id="footer">
      <tr>
          <td>
              {% for (var i=0; i<o.notes.length; i++) { %}
                <div><i>*</i>&nbsp;{%=o.notes[i]%}</div>
              {% } %}
              {% if (o.isShowFlatshow) { %}
                <div class="pdfMadeBy">&nbsp;${MADE_BY_TEXT}</div>
              {% } %}
          </td>
          <td id="pageNumber" valign="bottom">{%=o.pageNumber%}</td>
      </tr>
  </table>
</div>
`;

const shortBody = `
<div style="background-color: {%=o.backgroundColor%};" class="shortStyle" id="pdf">
<div class="currentDate">{%=o.currentDate%}</div>
<table id="headerStyle" class="header">
  <tr>
    <td height="80" align="center" valign="bottom" style="padding-top: 10px">
      <div id="pdfLogo"><img src={%=o.clientLogoURL%} alt=""></div>
    </td>
    <td width="20"></td>
    <td height="80" valign="bottom">
      <table>
        <tr>
          <th>{%=o.complexAddressFieldName%}</th>
          {% if (o.phones.length >= 1) { %}
            <th>{%=o.salesDepartmentFieldName%}</th>
          {% } %}
        </tr>
        <tr>
          <td style="padding-right: 80px;" valign="bottom"><span>{%=o.complexAddress%}</span></td>
          {% if (o.phones.length >= 1) { %}
            <td>
              {% for (var i=0; i<o.phones.length; i++) { %}
                <span>{%=o.phones[i]%}</span><br>
              {% } %}
            </td>
          {% } %}
        </tr>
      </table>
    </td>
  </tr>
</table>
<div id="mainDataGrid">
  <div style="width: 82px;">
    <div id="dataTable">
      {% for (const [key, value] of Object.entries(o.placementData)) { %}
          <div><span>{%=key%}</span><strong>{%=value%}</strong></div>
      {% } %}
    </div>
  </div>
  <div id="mainImage">
    {% if (o.apartmentLayout) { %}
      <img src={%=o.apartmentLayout%} alt="">
    {% } %}
  </div>
</div>
<div id="mainHalfGrid">
  <div>
  {% if (o.sectionDiagram) { %}
    <img src={%=o.sectionDiagram%} alt="">
  {% } %}
  </div>
  <div id="compass">
  {% if (o.compass) { %}
    <img src={%=o.compass%} alt="">
  {% } %}
  </div>
  <div>
  {% if (o.floorPlan) { %}
    <img src={%=o.floorPlan%} alt="">
  {% } %}
  </div>
</div>
<div id="mainFacadeGrid">
    {% if (o.renderFacade) { %}
      <div id="mainImage">
        <img src={%=o.renderFacade%} alt="">
      </div>
    {% } %}
  <div>
    <div id="dataTable">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<table id="footer">
  <tr>
    <td>
      {% for (var i=0; i<o.notes.length; i++) { %}
        <div><i>*</i>&nbsp;{%=o.notes[i]%}</div>
      {% } %}
      {% if (o.isShowFlatshow) { %}
        <div class="pdfMadeBy">&nbsp;${MADE_BY_TEXT}</div>
      {% } %}
    </td>
    <td id="pageNumber" valign="top">1</td>
  </tr>
</table>
</div>
`;

const paymentBody = `
  <div class="paymentPlan" style="background-color: {%=o.styles.backgroundColor%};" id="pdf">
  <div class="currentDate">{%=o.currentDate%}</div>
    <div class="paymentPageHeader">
        <div class="paymentTop">
            <div id="pdfLogo"><img class="pdfLogoImg" src={%=o.clientLogoURL%} alt=""></div>
            <div class="paymentTopInfo">
              <span class="planName" style="color: {%=o.styles.textColor%};">{%=o.name%}</span>
              <strong class="appartmentName" style="color: {%=o.styles.textColor%};">{%=o.uniqueUnitName%}</strong>
            </div>
        </div>
        <table class="paymentPageInfo">
            {% for (var i = 0; i < o.unitInfos.length; i++) { %}
                <tr style="color: {%=o.styles.textColor%};">
                    <td>{%=o.unitInfos[i].name%}</td>
                    <td>{%=o.unitInfos[i].value%}{% if (o.unitInfos[i].suffix) { %}<span>{%=o.unitInfos[i].suffix%}</span>{% } %}</td>
                </tr>
            {% } %}
        </table>
        <table class="paymentTableHead" style="background-color: {%=o.styles.headerColor%};">
            <tr>
                <td style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=o.config.stageColumnName%}</td>
                <td style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=o.config.descriptionColumnName%}</td>
                <td style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=o.config.percentageColumnName%}</td>
                <td style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=o.config.amountColumnName%}</td>
                <td style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=o.config.currencyColumnName%}</td>
            </tr>
        </table>
    </div>
    {% for (var groupIndex = 0; groupIndex < o.config.groups.length; groupIndex++) { %}
        {% var group = o.config.groups[groupIndex]; %}
        {% var hasAdvancedSumPayment = group.payments.some(function(payment) { return payment.type === "AdvancedSumPayment"; }); %}
        {% if (group.name) { %}
            <div class="paymentTitle" style="background-color: {%=o.styles.headerColor%}; color: {%=o.styles.textColor%};">{%=group.name%}</div>
        {% } %}

        {% for (var paymentIndex = 0; paymentIndex < group.payments.length; paymentIndex++) { %}
            {% var payment = group.payments[paymentIndex]; %}
            {% if (hasAdvancedSumPayment) { %}
                <table class="deposit">
                    <tr>
                        <th style="background-color: {%=o.styles.dividingLinesColor%}; color: {%=o.styles.textColor%};">{%=payment.stageColumnValue%}</th>
                        <th style="background-color: {%=o.styles.dividingLinesColor%}; color: {%=o.styles.textColor%};">{%=o.calculateDate(payment.descriptionColumnValue)%}</th>
                        <th style="background-color: {%=o.styles.dividingLinesColor%}; color: {%=o.styles.textColor%};">{%=payment.percentageColumnValue%}</th>
                        <th style="background-color: {%=o.styles.dividingLinesColor%}; color: {%=o.styles.textColor%};">{%=payment.amountColumnValue%}</th>
                        <th style="background-color: {%=o.styles.dividingLinesColor%}; color: {%=o.styles.textColor%};">{%=payment.currencyColumnValue%}</th>
                    </tr>
                    {% if (payment.advancedFormulas && payment.advancedFormulas.length > 0) { %}
                        {% for (var formulaIndex = 0; formulaIndex < payment.advancedFormulas.length; formulaIndex++) { %}
                            {% var formula = payment.advancedFormulas[formulaIndex]; %}
                            <tr>
                                <td style="color: {%=o.styles.textColor%};">&nbsp;</td>
                                <td style="color: {%=o.styles.textColor%};">{% if (formula.descriptionColumnValue) { %}{%= o.calculateDate(formula.descriptionColumnValue) %}{% } else { %}&nbsp;{% } %}</td>
                                <td style="color: {%=o.styles.textColor%};">{% if (formula.percentageColumnValue) { %}{%= formula.percentageColumnValue %}{% } else { %}&nbsp;{% } %}</td>
                                <td style="color: {%=o.styles.textColor%};">{%=formula.amountColumnValue%}</td>
                                <td style="color: {%=o.styles.textColor%};">{%=formula.currencyColumnValue%}</td>
                            </tr>
                        {% } %}
                    {% } %}
                </table>
            {% } else { %}
                <table class="paymentListItem" style="background-color: {%=(paymentIndex % 2 === 0) ? o.styles.dividingLinesColor : 'none'%};">
                    <tr style="color: {%=o.styles.textColor%};">
                        <td>{%=payment.stageColumnValue%}</td>
                        <td>{%=o.calculateDate(payment.descriptionColumnValue)%}</td>
                        <td>{%=payment.percentageColumnValue%}</td>
                        <td>{%=payment.amountColumnValue%}</td>
                        <td>{%=payment.currencyColumnValue%}</td>
                    </tr>
                </table>
            {% } %}
        {% } %}
    {% } %}
    <div class="paymentPageFooter">
        <div class="footnote">
            {% for (var j = 0; j < o.notes.length; j++) { %}
                <p>{%=o.notes[j].noteText%}</p>
            {% } %}
            {% if (o.isShowFlatshow) { %}
              <p class="pdfMadeBy">${MADE_BY_TEXT}</p>
            {% } %}
        </div>
        <span class="pageNumber" style="color: {%=o.styles.textColor%};">{%=o.pageNumber%}</span>
    </div>
  </div>
`;

export const insertHtmlForPdf = () => {
  if (!document.querySelector('#pdf-template')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'pdf-template';
    script.innerHTML = bodyScript;
    document.body.appendChild(script);
  }

  if (!document.querySelector('#pdf-template-short')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'pdf-template-short';
    script.innerHTML = shortBody;
    document.body.appendChild(script);
  }

  if (!document.querySelector('#payment-template')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'payment-template';
    script.innerHTML = paymentBody;
    document.body.appendChild(script);
  }
};

const tableBody = `
<div style="background-color: white;" class="tablePage" id="pdf">
        <div class="tablePageHeader">
            <div class="logos">
              <div class="logoLeft"><img src="{%=o.projectLogo%}" alt="Project Logo"></div>
              <div class="logoRight"><img src="{%=o.developerLogo%}" alt="Developer Logo"></div>
            </div>
            <div class="salesDepartmentFieldName">{%=o.salesDepartmentFieldName%}</div>
            <div class="issuedDate">{%=o.issuedDate%}</div>
        </div>
        <table class="projectTable">
            <thead>
                <tr>
                    {% for (var i = 0; i < o.tableFields.length; i++) { %}
                        <th>{%=o.tableFields[i]%}</th>
                    {% } %}
                </tr>
            </thead>
            <tbody>
                {% for (var j = 0; j < o.tableData.length; j++) { %}
                    <tr>
                        {% for (var f = 0; f < o.tableFields.length; f++) { %}
                            <td>{%=o.tableData[j][o.tableFields[f]]%}</td>
                        {% } %}
                    </tr>
                {% } %}
            </tbody>
        </table>
        {% if (o.showFlatShowLogo) { %}
        <div class="pdfMadeBy">
          made by www.flat.show
        </div>
        {% } %}
        <div class="tablePageFooter">
            <span class="pageNumber">{%=o.pageNumber%}</span>
        </div>
    </div>
`;

export const insertHtmlForTablePdf = () => {
  if (!document.querySelector('#tablePage-template')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'tablePage-template';
    script.innerHTML = tableBody;
    document.body.appendChild(script);
  }
};
