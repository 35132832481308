import { LocationChangePayload } from 'connected-react-router';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { Actions, ActionTypes } from 'src/store/marketplace/actions';
import { IMarketplace, IMarketplaceRequestFilter } from 'src/components/profileComponents/Marketplace/types';
import { Actions as ActionsRequestAppParams } from 'src/store/requests/app/actions';
import { getCurrentLang } from 'src/store/app/selectors';
import { getCurrentMarketplace, getMarketplaceWhereConditions } from 'src/store/marketplace/selectors';
import { IMarketplaceFilterResponse } from 'src/typings/marketplace';
import { Hydra } from 'src/api/hydraResponse';

export function* setMarketplaceIdWatchSaga(action: {
  type: string,
  payload: LocationChangePayload,
}) {
  const buildType = getBuildType();
  const isMarketplace = buildType === BuildEnv.marketplace;
  if (action.payload.isFirstRendering && isMarketplace) {
    if (window.complexId === 'COMPLEX_ID') {
      const string = new URL(document.URL).pathname.split('/');
      const marketplaceIdString: string = string[string.length - 1];
      let marketplaceId: string | null | undefined = `/${process.env.REACT_APP_COMPLEX_ID}`;
      let currentMarketplace: IMarketplace | undefined;
      const getParams = '&properties[]=id&properties[]=frontConfiguration&properties[]=language&properties[]=name&properties[]=secondaryLanguages&properties[]=shortUniqueName&properties[]=developers';
      // @ts-ignore
      if (!Number.isNaN(parseFloat(marketplaceIdString)) && !Number.isNaN(marketplaceIdString - 0)) {
        marketplaceId = `?id=${marketplaceIdString}${getParams}`;
      } else if (marketplaceIdString && marketplaceIdString !== 'app') {
        marketplaceId = `?shortUniqueName=${marketplaceIdString}${getParams}`;
      }
      // @ts-ignore
      const response: Hydra<Partial<IMarketplace[]>> = yield call(async () => {
        const fetchResponse = await fetch(`${process.env.REACT_APP_REST_API}/api/marketplaces${marketplaceId}`);
        const data = await fetchResponse.json();
        return data;
      },
      ActionsRequestAppParams.getAppDataSuccess,
      ActionsRequestAppParams.getAppDataFail);
      if (response) {
        currentMarketplace = response['hydra:member'][0];
      }
      yield put(Actions.setCurrentMarketplace(currentMarketplace));
      yield put(Actions.setMarketplaceLanguage(currentMarketplace?.language));
    }
  }
}

export function* setMarketplaceInitialFilters(action: {
  type: string,
  payload: LocationChangePayload,
}) {
  const marketplace: IMarketplace = yield select(getCurrentMarketplace);
  if (marketplace) {
    const filterResponse: IMarketplaceFilterResponse = yield call(async () => {
      const fetchResponse = await fetch(`${process.env.REACT_APP_REST_API}/api/cntrlr/marketplace/filter`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/ld+json',
          },
          body: JSON.stringify({
            language: marketplace.language,
            marketplaceId: marketplace.id,
          }),
        });
      const data = await fetchResponse.json();
      return data;
    });
    yield put(Actions.setMarketplaceFiltersInitial(filterResponse.filter));
    yield put(Actions.setMarketplaceComplexesTile(filterResponse.complexesTile));
    yield put(Actions.setMarketplacePlacementsTile(filterResponse.placementsTile));
  }
}

export function* setMarketplaceFilters(action: {
  type: string,
  payload: LocationChangePayload,
}) {
  const marketplace: IMarketplace = yield select(getCurrentMarketplace);
  const whereConditions: IMarketplaceRequestFilter[] = yield select(getMarketplaceWhereConditions);
  if (marketplace) {
    const filterResponse: IMarketplaceFilterResponse = yield call(async () => {
      const fetchResponse = await fetch(`${process.env.REACT_APP_REST_API}/api/cntrlr/marketplace/filter`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/ld+json',
          },
          body: JSON.stringify({
            language: marketplace.language,
            marketplaceId: marketplace.id,
            whereConditions,
          }),
        });
      const data = await fetchResponse.json();
      return data;
    });
    yield put(Actions.setMarketplaceFilters(filterResponse.filter));
    yield put(Actions.setMarketplaceComplexesTile(filterResponse.complexesTile));
    yield put(Actions.setMarketplacePlacementsTile(filterResponse.placementsTile));
  }
}

export function* getMarketplaceIdWatchSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', setMarketplaceIdWatchSaga);
  yield takeEvery([ActionTypes.SET_MARKETPLACE], setMarketplaceInitialFilters);
  yield takeEvery([
    ActionTypes.SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS,
    ActionTypes.SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS,
    ActionTypes.SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS,
  ], setMarketplaceFilters);
}
