import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import { PopupStateContext } from 'src/components/Marketplace/components/MarketplaceFilterHeader/context';
import {
  IMarketplaceFilterCheckboxAllValues,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import { getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { RoomsFilterPopup } from './RoomsFilterPopup';
import { useStyles } from './styles';

interface Props {
  selection: IMarketplaceFilterCheckboxAllValues,
  setIsPopupOpen: (state: string | null) => void,
  closePopup: () => void,
}

export const RoomsFilter: React.FC<Props> = ({ selection, setIsPopupOpen, closePopup }) => {
  const styles = useStyles();
  const lang = useSelector(getMarketplaceLanguage);
  const [isPopupOpen, setIsLocalPopupOpen] = useState<boolean>(false);
  const [activeRooms, setActiveRooms] = useState<string[]>([]);
  const filterType = 'RoomsFilter';

  const openPopupType = useContext(PopupStateContext);

  const appliedButtonsText = useMemo(() => {
    return activeRooms.length ? activeRooms.join(', ') : null;
  }, [activeRooms]);

  useEffect(() => {
    if (openPopupType !== filterType && openPopupType !== null) {
      setIsLocalPopupOpen(false);
    }
  }, [openPopupType, setIsLocalPopupOpen]);

  const togglePopup = () => {
    const newState = !isPopupOpen;
    setIsLocalPopupOpen(newState);
    setIsPopupOpen(newState ? 'RoomsFilter' : null);
  };

  return (
    <Box
      className={`${styles.container}  ${isPopupOpen ? 'show' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        togglePopup();
      }}
    >
      {lang && (
        <>
          {selection.name?.[lang] && (
          <h3 className={styles.title}>{selection.name[lang]}</h3>
          )}
          {selection.callToActionText?.[lang] && (
          <p className={styles.subtitle}>{ appliedButtonsText || selection.callToActionText?.[lang] }</p>
          )}

          { isPopupOpen && (
            <RoomsFilterPopup
              selection={selection}
              // isPopupOpen={isPopupOpen}
              // setIsPopupOpen={setIsLocalPopupOpen}
              // selectedRooms={selectedRooms}
              setActiveRooms={setActiveRooms}
              closePopup={() => {
                togglePopup();
                closePopup();
              }}
            />
          ) }
        </>
      ) }
    </Box>
  );
};
