import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { appReducer } from 'src/store/app/reducer';
import { floorsReducer } from 'src/store/floors/reducer';
import { layoutsReducer } from 'src/store/layouts/reducer';
import { placementReducer } from 'src/store/placement/reducer';
import { placementViewConfigReducer } from 'src/store/placementViewConfig/reducer';
import { tableReducer } from 'src/store/table/reducer';
import { marketplaceReducer } from 'src/store/marketplace/reducer';
import { spinnerReducer } from './spinner/reducer';
import { filterParamsReducer } from './filter/filter-params/reducers';
import { filteredDataReducer } from './filter/filtered-data/reducers';
import { infrastructureReducer } from './infrastructure/reducer';
import { filterValueReducer } from './filter/filter-value/reducers';
import { typeDisplayedSpinnerReducer } from './type-displayed-spinner/reducer';
import { view2dReducer } from './view-2d/reducer';
import { crosstableReducer } from './crosstable/reducer';
import { panoramaReducer } from './panorama/reducer';
import { requestsReducer } from './requests/reducer';

export const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  requests: requestsReducer,
  app: appReducer,
  floors: floorsReducer,
  infrastructure: infrastructureReducer,
  layouts: layoutsReducer,
  spinner: spinnerReducer,
  filterParams: filterParamsReducer,
  filterValue: filterValueReducer,
  filteredData: filteredDataReducer,
  typeDisplayedSpinner: typeDisplayedSpinnerReducer,
  view2d: view2dReducer,
  table: tableReducer,
  placement: placementReducer,
  placementViewConfig: placementViewConfigReducer,
  crosstable: crosstableReducer,
  panorama: panoramaReducer,
  marketplace: marketplaceReducer,
});
