import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  marketplacePage: {
    height: '100%',
    fontSize: '18px',
    fontWeight: '300',
  },
  searchHeader: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    '-webkit-text-stroke-width': '0.2px',
    '-webkit-text-stroke-color': '#000',
  },
  actionBtn: {
    padding: '10px 20px',
  },
  borderBottom: {
    borderBottom: '1px solid #E8E6EE',
  },

  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  closeBtn: {
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
  addBtnWrapper: {
    marginTop: '21px',
    marginBottom: '36px',
  },
  addBtn: {
    backgroundColor: '#F9E8FF',
    padding: '10px 16px',
    borderRadius: '20px',
    height: '40px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.6px',
  },
}));
