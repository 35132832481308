/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { RefObject, useRef, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import PhoneIcon from '@mui/icons-material/Phone';
import { Hidden, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import { ButtonApp } from 'src/components/Button';
import {
  useAppMenuState,
  useAppOpenModalBookModal,
  useAppVisibilityBtn,
  useGenerateUlrLink,
  useGetListOfPhones,
  useAttributesContactForm,
  useBuildType,
} from 'src/hooks';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { getComplexId } from 'src/store/app/selectors';
import { ButtonLanguage } from 'src/components/ButtonLanguage';
import { AppFavoriteIcon } from 'src/components/AppHeaderFavoriteIcon';
import { pathList } from 'src/route-list';
import { requestSelectors } from 'src/store/requests/selectors';
import { SvgLinkHeader } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgLinkHeader';
import { useCopyUrlToClipboard } from 'src/hooks/useCopyUrlToClipboard';
import { useComparePageLink } from 'src/hooks/useComparePageLink';
import { SvgMenu } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgMenu';
import { AppHeaderLogo } from './ComponentLogo';
import { useStyles } from './styles';
import { IAppHeader } from './types';

export const AppHeader: React.FC<IAppHeader> = ({
  wrapperStyles,
}) => {
  const theme: Theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  const styles = useStyles({});
  const {
    filter_call,
    filter_share,
    filter_menu,
    filter_favorites,
    show_client_logo,
  } = useAppVisibilityBtn();

  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const refCopyInput: RefObject<HTMLInputElement> = useRef(null);
  const { shareFilterLink, handleGeneratorLink } = useGenerateUlrLink(refCopyInput);
  const complexId = useSelector(getComplexId);
  const { attributesForm } = useAttributesContactForm();
  const { handleToggleAppMenu } = useAppMenuState();
  const ringoClassname = useSelector(requestSelectors.app.getRingoClassname);
  const { getTranslations } = useTranslations();
  const { listOfPhones } = useGetListOfPhones();
  const { handleToggleModal } = useAppOpenModalBookModal();
  const { isCrossTable } = useBuildType();
  const { comparePageRouteLink } = useComparePageLink();
  const { copyUrlToClipboard } = useCopyUrlToClipboard();
  const pathsForCopyBtn = [pathList.floors, pathList.home];

  const handleOpenFavorite = useCallback(() => {
    const complexPrefix = isCrossTable ? `/${complexId}` : '';
    history.push(`${complexPrefix}${comparePageRouteLink}`);
  }, [complexId, comparePageRouteLink]);

  const handlePhoneBtn = useCallback(() => {
    if (matchesMd) {
      handleToggleModal();
    }
  }, [matchesMd, handleToggleModal]);

  return (
    <div className={`${styles.wrapperHeader} ${wrapperStyles}`}>
      <AppHeaderLogo isVisible={show_client_logo} />
      {!isCrossTable && (
      <div className={`${styles.headerWrapperButton}`}>
        { filter_favorites && location.pathname === pathList.compare && (
        <Hidden mdUp>
          <ButtonApp
            startIcon={<SvgLinkHeader />}
            className={styles.headerButton}
            onClick={copyUrlToClipboard}
          />
        </Hidden>
        )}
        <Hidden mdDown>
          <ButtonLanguage
            buttonStyles={styles.headerButton}
          />
        </Hidden>
        {
            filter_share && (
              <Hidden mdDown>
                {pathsForCopyBtn.includes(location.pathname) && (
                  <>
                    <input readOnly type="hidden" ref={refCopyInput} value={shareFilterLink} />
                    <Tooltip
                      title={getTranslations(locals.appTranslations.appCopyUrlParams)}
                    >
                      <ButtonApp
                        startIcon={<ShareIcon />}
                        className={styles.headerButton}
                        onClick={handleGeneratorLink}
                      />
                    </Tooltip>
                  </>
                )}
              </Hidden>
            )
          }
        {filter_call && listOfPhones?.length
          ? (
            <ButtonApp
              startIcon={<PhoneIcon />}
              className={`${styles.headerButton} ${attributesForm?.class}`}
              onClick={handlePhoneBtn}
            >
              {!matchesMd && (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className={`${ringoClassname ? `${ringoClassname}-btn` : ''} ${styles.callToUs}`}
                href={`tel:${listOfPhones[0].phone_href}`}
              />
              )}
            </ButtonApp>
          )
          : null}
        {
            filter_favorites && (
              <Hidden mdDown>
                <ButtonApp
                  startIcon={<AppFavoriteIcon />}
                  className={`${styles.headerButton} favoriteIcon`}
                  onClick={handleOpenFavorite}
                />
              </Hidden>
            )
          }
        {
            filter_menu && (
              <ButtonApp
                startIcon={<SvgMenu />}
                className={styles.headerButton}
                onClick={handleToggleAppMenu}
              />
            )
          }
      </div>
      )}
    </div>
  );
};
