import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { whiteColor } from 'src/constants';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  headerNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  navigation: {
    display: 'flex',
    background: 'rgba(30, 30, 30, 0.05)',
    borderRadius: '100px',
    padding: '4px',
    height: '64px',
    color: theme.palette.primary.main,

    [theme.breakpoints.down('md')]: {
      height: '52px',
    },
  },
  tab: {
    fontSize: '22px',
    border: '1px solid transparent',
    borderRadius: '100px',
    padding: '0 40px',
    cursor: 'pointer',
    transition: 'border 0.5s ease, background 0.5s ease',
    fontWeight: '500',
    color: theme.palette.primary.main,

    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },

    '&:hover': {
      background: 'rgba(30, 30, 30, 0.05)',
      border: '1px solid transparent',
    },
  },
  active: {
    background: whiteColor,
    border: '1px solid rgba(30, 30, 30, 0.1)',

    '&:hover': {
      border: '1px solid rgba(30, 30, 30, 0.45)',
      background: whiteColor,
    },
  },
}));
