import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import { IMarketplaceFilterCheckboxAllValues } from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';

interface Props {
  selection?: IMarketplaceFilterCheckboxAllValues,
  // isPopupOpen: boolean,
  // setIsPopupOpen?: (value: boolean) => void,
  // selectedRooms: string[],
  setActiveRooms?: (value: any) => void,
  closePopup?: () => void,
  customClass?: string
  showActions?: boolean,
  onApply?: () => void,
  renderType?: 'checkbox' | 'button',
}

export const RoomsFilterPopup: React.FC<Props> = ({
  // isPopupOpen,
  selection,
  // setIsPopupOpen,
  // selectedRooms,
  setActiveRooms,
  onApply,
  closePopup,
  customClass,
  showActions = true,
  renderType = 'checkbox', // Новый пропс: "checkbox" или "button"
}) => {
  const styles = useStyles();
  const popupRef = useRef(null);
  const {
    marketplaceFilters, getMarketplaceInitialFilter, setWhereConditions, getFilterEqualsActiveData, resetFilterWhereConditions,
  } = useMarketPlaceFilter();
  const [initialOptions, setInitialOptions] = useState<IMarketplaceFilterResponseValue[] | undefined>();
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getFilterEqualsActiveData(selection?.property?.systemName);
      setSelectedRooms(value);
      if (setActiveRooms) {
        setActiveRooms(value);
      }
    }
  }, [selection, marketplaceFilters]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      setInitialOptions(getMarketplaceInitialFilter(selection.property.systemName)?.values || []);
    }
  }, [selection, marketplaceFilters]);

  const toggleRoomSelection = (room: IMarketplaceFilterResponseValue) => {
    if (selection?.property?.systemName) {
      setWhereConditions([{
        propertyName: selection?.property?.systemName,
        equals: room.value,
      }]);
    }
  };

  const handleReset = () => {
    if (selection?.property?.systemName) {
      resetFilterWhereConditions([selection?.property.systemName]);
    }
  };

  return (

    <Box
      className={`${styles.popup} ${customClass || ''}`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`${styles.content} ${
          renderType === 'button'
            ? styles.contentWithButtons
            : ''
        }`}
      >
        {initialOptions && initialOptions.map((room) => (
          <div key={room.value} className={styles.checkboxItem}>
            {renderType === 'checkbox' ? (
              <>
                <input
                  type="checkbox"
                  id={room.value}
                  className="lCheckbox"
                  checked={selectedRooms?.includes(room.value)}
                  onChange={() => toggleRoomSelection(room)}
                />
                <label htmlFor={room.value}>
                  { room.value }
                </label>
                <span className={styles.counter}>
                  (
                  { room.unitCount }
                  )
                </span>
              </>
            ) : (
              <button
                type="button"
                className={`lBtn ${
                  selectedRooms?.includes(room.value)
                    ? 'active'
                    : ''
                }`}
                onClick={() => toggleRoomSelection(room)}
              >
                {room.value}
              </button>
            )}
          </div>
        ))}
      </div>

      {showActions && (
      <FilterActions
        onReset={handleReset}
        onApply={() => {
          if (onApply) onApply();
          if (closePopup) closePopup();
        }}
      />
      )}
    </Box>
  );
};
