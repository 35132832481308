import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  uiComponentsWrapper: {
    display: 'flex',
    gap: '36px',
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '52px',
    width: '252px',
    borderBottom: '2px solid #5D5FEF',
    background: '#F7F5FF',
  },
  optionSwitch: {},
  optionLabel: {
    fontSize: '18px',
    fontWeight: '400',
  },
  optionActions: {
    height: '20px',
    width: '20px',
    // '& svg': {
    //   color: '#6C59E6',
    //   cursor: 'pointer',
    // },
    // '& button': {
    //   border: 'none',
    //   cursor: 'pointer',
    //   display: 'flex',
    //   padding: '0',
    //   background: 'none',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // },
  },
  settingsContainer: {
    display: 'flex',
    gap: '36px',
  },
  headerSettings: {
    width: '312px',
  },
  headerSwitch: {
    marginRight: '8px',
  },
  headerSwitchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  headerSwitchText: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  headerBody: {},
  headerTitle: {
    borderRadius: '8px',
    border: '1px solid #9E9E9E',
    height: '52px',
  },
}));
