import { IDogeFields } from 'src/typings/global-config';
import { IDeveloper, IFileUri } from 'src/typings/complexes';
import { LocationButtonsEnum } from 'src/components/profileComponents/Marketplace/components/Filters/constants';
import { IPlacementProperty } from 'src/typings/viewConfig';

export interface IMarketplace extends IDogeFields {
  id: number;
  name: { [ket: string]: string },
  shortUniqueName: string,
  developers: IDeveloper[],
  language: string,
  secondaryLanguages: string[],
  frontConfiguration: {
    module: IMarketplaceModule
  },
  placementProperties: IMarketplacePlacementProperty[],
}
export interface IMarketplacePlacementProperty extends IPlacementProperty {
  diType: 'PlacementProperty'
}
export interface IMarketplaceModuleFiltersConfiguration {
  primarySection: IMarketplaceModulePrimarySection,
  secondaries: IMarketplaceModulePrimarySectionType[],
}
export interface IMarketplaceAccessUrl extends IDogeFields {
  id: number,
  url: string;
}
export interface IMarketplaceModule {
  filtersConfiguration: IMarketplaceModuleFiltersConfiguration;
  clientAccessUrls: IMarketplaceAccessUrl[] | null;
}

export interface IMarketplaceModulePrimarySection {
  selections: IMarketplaceModulePrimarySectionType[];
  tag: IMarketplaceFilterTagSelectedValues
}
export interface IMarketplaceFilterBase {
  id: number,
  type: IMarketplaceFilterType
  sortOrder: number,
  name: IFieldWithTranslation | null,
  callToActionText: IFieldWithTranslation | null,
  accordionState: AccordionStateType | null,
  isAccordionOpen?: boolean
}

export interface IMarketplaceFilterLocationFilter extends IMarketplaceFilterBase {
  type: IMarketplaceFilterType.NestedAllValuesTag,
  subType?: LocationButtonsEnum,
  property: IMarketplaceProperty | null,
  propertyValuesOrder: SortOrderType | null,
  subFilters: IMarketplaceFilterLocationFilter[]
}

export interface IMarketplaceFilterLocation extends IMarketplaceFilterBase {
  filters: IMarketplaceFilterLocationFilter[]
}

export interface IMarketplaceFilterTagSelectedValues extends IMarketplaceFilterBase {
  selectedProperties: IMarketplaceFilterTagSelectedValueSelectedProperty[];
}

export interface IMarketplaceFilterTagAllValues extends IMarketplaceFilterBase{
  property: IMarketplaceProperty | null,
  propertyValuesOrder: SortOrderType | null
}

export interface IMarketplaceFilterCheckboxAllValues extends IMarketplaceFilterBase {
  property: IMarketplaceProperty | null,
  propertyValuesOrder: SortOrderType | null
}

export interface IMarketplaceFilterRange extends IMarketplaceFilterBase {
  fromName?: IFieldWithTranslation,
  toName?: IFieldWithTranslation,
  property: IMarketplaceProperty | null,
  mainUom?: IFieldWithTranslation,
  secondaryUom?: IFieldWithTranslation,
  uomRate?: number
}

export interface IFieldWithTranslation {
  [key: string]: string;
}

export interface IMarketplaceProperty {
  '@id'?: string,
  systemName: string,
  translates: IFieldWithTranslation | null
}

export interface IMarketplaceFilterTagSelectedValueSelectedProperty {
  id: number,
  sortOrder: number,
  property: IMarketplaceProperty | null,
  value: IFieldWithTranslation,
  iconFileUri: IFileUri | null,
}

export interface IMarketplaceLocationRequestFilter {
  propertyName: string;
  equals: string
}

export interface IMarketplaceRangeRequestFilter {
  propertyName: string;
  min: number,
  max: number,
}

export type IMarketplaceRequestFilter =
  IMarketplaceLocationRequestFilter
| IMarketplaceRangeRequestFilter

export type IMarketplaceModulePrimarySectionType =
  IMarketplaceFilterLocation
  | IMarketplaceFilterTagSelectedValues
  | IMarketplaceFilterTagAllValues
  | IMarketplaceFilterRange
  | IMarketplaceFilterCheckboxAllValues

export enum IMarketplaceFilterType {
  GroupingFilter = 'GroupingFilter',
  AllValuesRange = 'AllValuesRange',
  AllValuesCheckbox = 'AllValuesCheckbox',
  AllValuesTag = 'AllValuesTag',
  NestedAllValuesTag = 'NestedAllValuesTag',
  SelectedValuesTag = 'SelectedValuesTag',
  ObjectFilter = 'ObjectFilter',
}

export interface Option {
  label: string;
  value: string;
}

export type AccordionStateType = 'none' | 'open' | 'closed';
export const accordionStateOptions = [{ label: 'none', value: 'none' }, { label: 'open', value: 'open' }, { label: 'closed', value: 'closed' }];
export type SortOrderType = 'A-Z_0-9' | '0-9_A-Z' | 'object.id_0-9' | 'object.id_9-0' | 'object.sortOrder_0-9' | 'object.sortOrder_9-0';
export const sortOrderOptions = [
  { label: 'A -> Z, then 1 -> 9', value: 'A-Z_0-9' },
  { label: '1 -> 9, then A -> Z', value: '0-9_A-Z' },
  { label: 'By ID (S -> L)', value: 'object.id_0-9' },
  { label: 'By ID (L -> S)', value: 'object.id_9-0' },
  { label: 'object.sortOrder_0-9', value: 'object.sortOrder_0-9' },
  { label: 'object.sortOrder_9-0', value: 'object.sortOrder_9-0' },
];

export const sortOrderOptionsNotProjectName = [
  { label: 'A -> Z, then 1 -> 9', value: 'A-Z_0-9' },
  { label: '1 -> 9, then A -> Z', value: '0-9_A-Z' },
  { label: 'object.sortOrder_0-9', value: 'object.sortOrder_0-9' },
  { label: 'object.sortOrder_9-0', value: 'object.sortOrder_9-0' },
];

export const sortOrderOptionsMap = {
  'A-Z_0-9': 'A -> Z, then 1 -> 9',
  '0-9_A-Z': '1 -> 9, then A -> Z',
  'object.id_0-9': 'By ID (S -> L)',
  'object.id_9-0': 'By ID (L -> S)',
  'object.sortOrder_0-9': 'object.sortOrder_0-9',
  'object.sortOrder_9-0': 'object.sortOrder_9-0',
};
