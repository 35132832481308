import React from 'react';
import { Complexes } from 'src/components/profileComponents/Complexes';
import { Developers } from 'src/components/profileComponents/Developers';
import { DeveloperPage } from 'src/components/profileComponents/DeveloperPage';
import { Integrations } from 'src/components/profileComponents/Integrations';
import { BitrixCategories } from 'src/components/profileComponents/BitrixCategories';
import { EditComplex } from 'src/components/profileComponents/EditComplex';
import { Storage } from 'src/components/profileComponents/Storage';
import { Manager, TypeOfPage } from 'src/components/profileComponents/Manager';
import Box from '@mui/material/Box';
import { IRouteList } from 'src/route-list';
import { translations } from 'src/constants/translations';
import { HouseStorage } from 'src/components/profileComponents/HouseStorage';
import { CreatioStages } from 'src/components/profileComponents/Integrations/Creatio/components/CreatioStages';
import { NetHuntCategories } from 'src/components/profileComponents/Integrations/Nethunt/components/Categories';
import { Marketplace } from 'src/components/profileComponents/Marketplace';
import { MarketplaceSettings } from 'src/components/profileComponents/Marketplace/components/MarketplaceSettings';
import { UiComponents } from 'src/components/profileComponents/Marketplace/components/Ui-components';
import { Filters } from 'src/components/profileComponents/Marketplace/components/Filters';

export const pathList = {
  complexes: 'complexes',
  // managers: 'managers',
  developers: 'developers',
  marketplace: 'marketplaces',
  settings: 'admin',
};

export const linkList: IRouteList[] = [
  {
    path: pathList.complexes,
    name: translations.complexesLink,
  },
  // {
  //   path: pathList.managers,
  //   name: translations.managersLink,
  //   disabledForManager: true,
  // },
  {
    path: pathList.developers,
    name: translations.developersLink,
    disabledForManager: true,
    disabledForSupervisor: true,
  },
  {
    path: pathList.marketplace,
    name: translations.marketplaceLink,
    disabledForManager: true,
    disabledForSupervisor: true,
    disabledForProd: true,
  },
  {
    path: pathList.settings,
    name: translations.settingsLink,
    disabledForManager: true,
    disabledForSupervisor: false,
    isAbsoluteLink: true,
  },
];

export const profileList: IRouteList[] = [
  {
    path: 'complexes',
    name: translations.complexesLink,
    Component: <Complexes />,
  },
  {
    path: 'complexes/:id',
    name: translations.complexesLink,
    Component: (<EditComplex />),
  },
  {
    path: 'complexes/:id/storage',
    name: translations.goToStorage,
    Component: (<Storage />),
  },
  {
    path: 'complexes/:id/storage/:houseId',
    name: translations.goToStorage,
    Component: (<HouseStorage />),
  },
  {
    path: 'complexes/:id/manager',
    name: translations.complexesLink,
    Component: (<Manager type={TypeOfPage.addWithComplex} />),
  },
  {
    path: 'complexes/:id/manager/:userId',
    name: translations.complexesLink,
    Component: (<Manager type={TypeOfPage.editWithComplex} />),
  },
  {
    path: 'managers',
    name: translations.managersLink,
    Component: (<Box>managers</Box>),
  },
  {
    path: 'developers',
    name: translations.developersLink,
    Component: (<Developers />),
  },
  {
    path: 'developers/:id',
    name: translations.developersLink,
    Component: (<DeveloperPage />),
  },
  {
    path: 'developers/:id/bitrix/integration',
    name: translations.developersLink,
    Component: (<Integrations integration="bitrix" />),
  },
  {
    path: 'developers/:id/creatio/integration',
    name: translations.developersLink,
    Component: (<Integrations integration="creatio" />),
  },
  {
    path: 'developers/:id/nethunt/integration',
    name: translations.developersLink,
    Component: (<Integrations integration="netHunt" />),
  },
  {
    path: 'developers/:id/bitrix/category/:categoryId',
    name: translations.developersLink,
    Component: (<BitrixCategories integration="bitrix" />),
  },
  {
    path: 'developers/:id/creatio/category/:categoryId',
    name: translations.developersLink,
    Component: (<CreatioStages integration="creatio" />),
  },
  {
    path: 'developers/:id/nethunt/funnel/:funnelId',
    name: translations.developersLink,
    Component: (<NetHuntCategories integration="netHunt" />),
  },
  {
    path: 'marketplaces',
    name: translations.marketplaceLink,
    Component: (<Marketplace />),
  },
  {
    path: 'marketplaces/:id',
    name: translations.marketplaceLink,
    Component: (<MarketplaceSettings />),
  },
  {
    path: 'marketplaces/:id/ui-components',
    name: translations.marketplaceLink,
    Component: (<UiComponents />),
  },
  {
    path: 'marketplaces/:id/filters',
    name: translations.marketplaceFiltersLink,
    Component: (<Filters />),
  },
  {
    path: 'settings',
    name: translations.settingsLink,
    Component: (<Box>Settings</Box>),
  },
];
