import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import { PopupStateContext } from 'src/components/Marketplace/components/MarketplaceFilterHeader/context';
import { TypeFilterPopup } from 'src/components/Marketplace/components/TypeFilter/TypeFilterPopup';
import { useSelector } from 'react-redux';
import { getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import {
  IMarketplaceFilterTagAllValues,
} from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { useStyles } from './styles';

interface Props {
  selection: IMarketplaceFilterTagAllValues,
  setIsPopupOpen: (state: string | null) => void,
  closePopup: () => void,
}

export const TypeFilter: React.FC<Props> = ({ selection, setIsPopupOpen, closePopup }) => {
  const styles = useStyles();
  const lang = useSelector(getMarketplaceLanguage);
  const [isPopupOpen, setIsLocalPopupOpen] = useState<boolean | null>(false);
  const [activeButtons, setActiveButtons] = useState<string[]>([]);
  const filterType = 'TypeFilter';

  const openPopupType = useContext(PopupStateContext);

  const appliedButtonsText = useMemo(() => {
    return activeButtons.length ? activeButtons.join(', ') : null;
  }, [activeButtons]);

  useEffect(() => {
    if (openPopupType !== filterType && openPopupType !== null) {
      setIsLocalPopupOpen(false);
    }
  }, [openPopupType, setIsLocalPopupOpen]);

  const togglePopup = () => {
    const newState = !isPopupOpen;
    setIsLocalPopupOpen(newState);
    setIsPopupOpen(newState ? 'TypeFilter' : null);
  };

  return (
    <Box
      className={`${styles.container} ${isPopupOpen ? 'show' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        togglePopup();
      }}
    >
      {lang && (
      <>
        {selection.name?.[lang] && (
          <h3 className={styles.title}>{ selection.name[lang] }</h3>
        ) }
        {selection.callToActionText?.[lang] && (
          <p className={styles.subtitle}>{ appliedButtonsText || selection.callToActionText?.[lang] }</p>
        ) }

        {isPopupOpen && (
          <TypeFilterPopup
            selection={selection}
            setIsPopupOpen={setIsLocalPopupOpen}
            setCallToActionButtons={setActiveButtons}
            closePopup={closePopup}
          />
        ) }
      </>
      ) }
    </Box>
  );
};
