import React, { useCallback, useEffect, useState } from 'react';
import { translations } from 'src/constants/translations';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  UploadMarketplace,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/UploadMarketplace';
import { readURL } from 'src/helpers';
import {
  AccordionStateType,
  IMarketplace,
  IMarketplaceFilterTagSelectedValueSelectedProperty,
  IMarketplaceModulePrimarySectionType,
  IMarketplaceProperty,
  Option,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { useMarketplaces } from 'src/hooks/marketplace';
import Warning from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  useStyles,
} from './styles';

interface IPrimaryTagFilter {
  marketplace?: IMarketplace;
  entity: {item: IMarketplaceFilterTagSelectedValueSelectedProperty},
  lang: string,
  isNeedToChange: boolean,
  propertyOptions: IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]
}

export const PrimaryTagFilter: React.FC<IPrimaryTagFilter> = ({
  entity, lang, propertyOptions, marketplace, isNeedToChange,
}) => {
  const styles = useStyles();
  const [image, setImage] = useState<string | null>(entity.item.iconFileUri?.fileUrl || null);
  const [_isNeedToChange, setIsNeedToChange] = useState<boolean>(isNeedToChange);
  const [chooseValueOptions, setChooseValueOptions] = useState<Option[]>([]);
  const {
    marketplaceLanguageObjectsOptions, marketplaceLanguageObjectsIsLoading, fetchMarketplaceLanguageObjects,
  } = useMarketplaces();

  useEffect(() => {
    setIsNeedToChange(isNeedToChange);
  }, [isNeedToChange]);

  useEffect(() => {
    setChooseValueOptions(marketplaceLanguageObjectsOptions);
  }, [marketplaceLanguageObjectsOptions]);

  const onOpen = useCallback(async (findProperties: string[]) => {
    if (marketplace) {
      fetchMarketplaceLanguageObjects({
        marketplaceId: marketplace.id,
        findProperties,
      }, marketplace.language);
    }
  }, [marketplace]);

  return (
    <div className={styles.primaryTagGroupFields}>
      <UploadMarketplace
        register={() => {}}
        registerName=""
        image={image}
        onUpload={async (file, response) => {
          const tmp = entity;
          tmp.item.iconFileUri = response.data[0];
          const base64: string = await readURL(file);
          if (base64) {
            setImage(base64);
          }
        }}
      />
      <BoxFormAutocomplete
        label={translations.chooseProperty}
        name="property"
        defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
        onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
          const tmp = entity;
          if (tmp.item.property) {
            tmp.item.property = event.value;
          }
        }}
        options={propertyOptions}
      />
      <BoxFormAutocomplete
        label={translations.chooseValue}
        name="chooseValue"
        defaultValue={entity.item.value?.[lang]}
        isLoading={marketplaceLanguageObjectsIsLoading}
        onOpen={() => onOpen([entity.item.property?.systemName || ''])}
        onClose={() => setChooseValueOptions([])}
        onChange={(event: {label: string, value: string}) => {
          setInputValue<IMarketplaceFilterTagSelectedValueSelectedProperty>({ propLink: entity.item, propName: 'value' }, event.value, lang);
          setIsNeedToChange(false);
        }}
        options={chooseValueOptions}
      />
      { _isNeedToChange && (
        <Box display="flex" alignItems="center" marginRight={4}>
          <Tooltip title={<h3>{translations.isNeedToChange}</h3>} placement="top">
            <Warning color="warning" />
          </Tooltip>
        </Box>
      ) }
    </div>
  );
};
