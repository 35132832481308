import React, { useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { RandomInteger } from 'src/helpers/randomInteger';
import {
  IMarketplaceFilterTagSelectedValues,
  IMarketplaceFilterTagSelectedValueSelectedProperty,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import { getCurrentMarketplace } from 'src/store/marketplace/selectors';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';

interface Props {
  tag?: IMarketplaceFilterTagSelectedValues,
  activeOptions: IMarketplaceFilterTagSelectedValueSelectedProperty[]
  onOptionClick: (prop: IMarketplaceFilterTagSelectedValueSelectedProperty) => void,
  styles: any,
  view?: 'slider' | 'list'
}
export const FilterOptionSlider: React.FC<Props> = ({
  tag,
  activeOptions,
  onOptionClick,
  styles,
  view = 'slider',
}) => {
  // const {
  //   marketplaceFilters, getMarketplaceInitialFilter, getMarketplaceFilter, setWhereConditions,
  // } = useMarketPlaceFilter();
  const marketplace = useSelector(getCurrentMarketplace);

  // useEffect(() => {
  //   if (tag?.property?.systemName) {
  //     // setFilteredOptions(getMarketplaceFilter(selection.property.systemName)?.values);
  //     setSelectedRooms(getMarketplaceFilter(selection.property.systemName)?.values || []);
  //   }
  // }, [selection, marketplaceFilters]);

  const isActive = useCallback((option: IMarketplaceFilterTagSelectedValueSelectedProperty) => {
    return activeOptions.find((item) => item.value === option.value);
  }, [activeOptions]);

  return (
    <>
      {view === 'slider' && (
        <Swiper
          className={styles.filterOptions}
          modules={[Navigation]}
          spaceBetween={8}
          slidesPerView="auto"
          navigation
        >
          { tag && marketplace && tag.selectedProperties.map((option) => (
            <SwiperSlide key={RandomInteger()} className={styles.filterSlide}>
              <button
                className={`lBtn ${
                  isActive(option)
                    ? styles.active || 'active'
                    : ''
                }`}
                onClick={() => onOptionClick(option)}
                type="button"
              >
                <img className={styles.icon} src={option.iconFileUri?.fileUrl} alt="icon" />
                {option.value?.[marketplace?.language] || option.property?.systemName}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      ) }
      { view === 'list' && (
        <div className={styles.list}>
          { tag && marketplace && tag.selectedProperties.map((option) => (
            <button
              className={`lBtn ${
                isActive(option)
                  ? styles.active || 'active'
                  : ''
              }`}
              onClick={() => onOptionClick(option)}
              type="button"
            >
              <img className={styles.icon} src={option.iconFileUri?.fileUrl} alt="icon" />
              {option.value?.[marketplace?.language] || option.property?.systemName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
