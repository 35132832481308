import React from 'react';
import { useStyles } from './styles';

export const FilterActions: React.FC<any> = ({
  onReset,
  onApply,
  applyTitle = 'Застосувати',
  showActions = true,
}) => {
  const classes = useStyles();

  if (!showActions) return null;

  return (
    <div className={classes.actions}>
      <button
        className={classes.resetButton}
        onClick={(event) => {
          event.stopPropagation();
          onReset();
        }}
        type="button"
      >
        Скинути
      </button>
      <button
        className={classes.applyButton}
        onClick={(event) => {
          event.stopPropagation();
          onApply();
        }}
        type="button"
      >
        {applyTitle}
      </button>
    </div>
  );
};
