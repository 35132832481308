import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { IFilterTile } from 'src/typings/marketplace';
import { IComplex } from 'src/typings/complexes';
import { useSelector } from 'react-redux';
import { getCurrentMarketplace } from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

interface IFilterCard {
  card: IComplex
}

export const FilterCard: React.FC<IFilterCard> = ({
  card,
}) => {
  const styles = useStyles();
  const marketplace = useSelector(getCurrentMarketplace);

  return (
    <div className={styles.card}>
      <ul className={styles.list}>
        {/* {title.tags?.map((tag: any) => ( */}
        {/*  <li key={tag.id} className={styles.listItem}> */}
        {/*    {tag.icon && tag.icon} */}
        {/*    {tag.label} */}
        {/*  </li> */}
        {/* ))} */}
      </ul>

      {/* <div className={styles.imageWrapper}>
                <img src={image} alt={name} className={styles.image} />
            </div> */}

      <div className={styles.imageWrapper}>
        {/* Desktop View */}
        <div className={styles.desktopView}>
          <Swiper
            modules={[Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="custom-swiper"
          >
            {[card.previewFileUri?.fileUrl].map((img: any, index: number) => (
              <SwiperSlide key={img}>
                <img
                  src={img}
                  alt={`${img}-${index}`}
                  className={styles.image}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className={styles.hoverCard}>
        {/* TOP */}

        {marketplace && (
          <h3 className={styles.name}>{card.title[marketplace.language]}</h3>
        )}

        <ul className={styles.locationList}>
          {/* <li className={styles.locationListItem}> */}
          {/*  <img src={iconLocation} alt={location} /> */}
          {/*  {location} */}
          {/* </li> */}
          {/* <li className={styles.locationListItem}> */}
          {/*  <img src={iconWay} alt={way} /> */}
          {/*  {way} */}
          {/* </li> */}
        </ul>

        {/* <span className={styles.price}> */}
        {/*  {priceFrom} */}
        {/*  {' '} */}
        {/*  -*/}
        {/*  {priceTo} */}
        {/* </span> */}
        {/* TOP */}
        {/* <span className={styles.completionDate}>{completionDate}</span> */}

        {/* <ul className={styles.unitsList}> */}
        {/*  {units?.map((unit: any) => ( */}
        {/*    <li key={unit.type} className={styles.unitsListItem}> */}
        {/*      <span className={styles.type}>{unit.type}</span> */}
        {/*      <span className={styles.area}> */}
        {/*        від */}
        {/*        {' '} */}
        {/*        {unit.area} */}
        {/*      </span> */}
        {/*      <span className={styles.priceItem}> */}
        {/*        від */}
        {/*        {' '} */}
        {/*        {unit.price} */}
        {/*        {' '} */}
        {/*      </span> */}
        {/*    </li> */}
        {/*  ))} */}
        {/* </ul> */}

        <div className={styles.bottom}>
          {/* <span className={styles.units}>{details}</span> */}
          {/* <button className={styles.button} type="button">Детальніше</button> */}
        </div>
      </div>
    </div>
  );
};
