/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IDeveloper, ISpecificationProp } from 'src/typings/complexes';
import { useGetAuthData, useGetDevelopers } from 'src/hooks';
import { useLanguage } from 'src/hooks/app/use-language';
import TextField from '@mui/material/TextField';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { postMarketplace, putMarketplace } from 'src/api/marketplace';
import InputAdornment from '@mui/material/InputAdornment';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { IMarketplace } from 'src/components/profileComponents/Marketplace/types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import { DeveloperPropertyList } from 'src/components/profileComponents/Developers/components/DeveloperPropertyList';
import { Add } from '@mui/icons-material';
import { useStyles } from '../styles';

interface MarketplaceModalInput {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshMarketplaces: Function;
  marketplace?: IMarketplace | null;
}

const schema = yup.object().shape({
  name: yup.string().required(translations.requiredField),
  language: yup.string().required(translations.requiredField),
});

export const MarketplaceModal: React.FC<MarketplaceModalInput> = ({
  isOpen, handleChangeOpen, marketplace, refreshMarketplaces,
}) => {
  const styles = useStyles();
  const [error, setError] = useState('');
  const { developers } = useGetDevelopers();
  const { languagesCodesOptions: allLanguagesOptions, getLanguagesList } = useLanguage();
  const [marketplacePrimaryLanguage, setMarketplacePrimaryLanguage] = useState<IDevelopmentSpecificationPropChange<string>>({ label: 'en', value: 'en' });
  const [marketplaceSecondaryLanguages, setMarketplaceSecondaryLanguages] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const [marketplaceDeveloper, setMarketplaceDeveloper] = useState<IDevelopmentSpecificationPropChange<IDeveloper>[] | undefined>();
  const [optionsList, setOptionsList] = useState<ISpecificationProp[]>([]);
  const [tabValue, setTabValue] = useState<string>('1');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const developersOptions = useMemo(() => {
    return developers.map((dev) => ({ label: dev.name[dev.language], value: dev }));
  }, [developers]);

  const {
    register, handleSubmit, formState, setValue, reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    getLanguagesList();
    if (marketplace) {
      const formData = {
        name: marketplace.name[marketplace.language],
        shortUniqueName: marketplace.shortUniqueName,
        language: marketplace.language,
        secondaryLanguages: marketplace.secondaryLanguages,
      };

      setMarketplacePrimaryLanguage({ label: marketplace.language, value: marketplace.language });
      setMarketplaceSecondaryLanguages(marketplace.secondaryLanguages.map((item) => ({ label: item, value: item })));
      setMarketplaceDeveloper(marketplace.developers.map((item) => ({ label: item.name[item.language], value: item })));
      setOptionsList(marketplace.frontConfiguration.module.clientAccessUrls?.map(
        (item, index) => ({
          ...item, // @ts-ignore: next-line
          name: item.url[marketplace.language],
          type: DeveloperPropertyType.purchaseTerms,
          prefix: { [marketplace.language]: '' },
          suffix: { [marketplace.language]: '' },
          sortOrder: index,
        }),
      ) || []);
      reset(formData);
    } else {
      reset({
        language: marketplacePrimaryLanguage.value,
        shortUniqueName: undefined,
      });
    }
  }, [marketplace]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const addNew = (type: DeveloperPropertyType) => {

  };

  const addNewBtn = (type: DeveloperPropertyType) => (
    <ButtonApp
      variant="outlined"
      className={styles.addBtn}
      startIcon={<Add />}
      onClick={() => addNew(type)}
    >
      Add new
    </ButtonApp>
  );

  const close = () => handleChangeOpen(false);

  const onSubmit = async (formData: any) => {
    let res;
    const saveData = {
      name: { [formData.language]: formData.name },
      shortUniqueName: formData.shortUniqueName,
      language: formData.language,
      secondaryLanguages: formData.secondaryLanguages,
      developers: formData.developers,
    };
    if (marketplace) {
      res = await putMarketplace(jwt, marketplace.id, saveData);
    } else {
      res = await postMarketplace(jwt, saveData);
    }
    if (res.ok) {
      close();
      refreshMarketplaces();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  const nameChange = (value: string) => {
    setValue('name', value);
  };

  const developersChange = (value: IDevelopmentSpecificationPropChange<IDeveloper>[]) => {
    setValue('developers', value.map((item) => item.value));
    setMarketplaceDeveloper(value);
  };

  const languageChange = (value: IDevelopmentSpecificationPropChange<string>) => {
    setValue('language', value.value);
    setMarketplacePrimaryLanguage(value);
  };

  const secondaryLanguagesChange = (value: IDevelopmentSpecificationPropChange<string>[]) => {
    setValue('secondaryLanguages', value.map((item) => item.value));
    setMarketplaceSecondaryLanguages(value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="950px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {marketplace ? translations.marketplaceEditing : translations.addMarketplace}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="MAIN INFORMATION" value="1" />
                        <Tab label="Access urls" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <Box flex="1" display="flex" gap="40px">
                          <Box mb={6} display="flex" flexDirection="column" width="100%">
                            { translations.marketplaceName }
                            <Box mt={2}>
                              <TextField
                                id="name"
                                variant="outlined"
                                name="name"
                                onChange={(event) => nameChange(event.target.value)}
                                inputRef={register}
                                {...register('name')}
                              />
                            </Box>
                          </Box>
                          <Box mb={6} display="flex" flexDirection="column" width="100%">
                            <BoxFormTextField
                              startAdornment={<InputAdornment position="start">realty.market/</InputAdornment>}
                              label={translations.marketplaceShortName}
                              name="shortUniqueName"
                              inputRef={register}
                              {...register('shortUniqueName')}
                            />
                          </Box>
                        </Box>
                        <Box flex="1" display="flex" gap="40px">
                          <Box mb={6} display="flex" flexDirection="column" width="100%">
                            <BoxFormAutocomplete
                              label={translations.primaryLanguage}
                              name="language"
                              options={allLanguagesOptions}
                              value={marketplacePrimaryLanguage}
                              onChange={(value: IDevelopmentSpecificationPropChange<string>) => languageChange(value)}
                              {...register('language')}
                            />
                          </Box>
                          <Box mb={6} display="flex" flexDirection="column" width="100%">
                            <BoxFormAutocomplete
                              multiple
                              label={translations.secondaryLanguages}
                              name="secondaryLanguages"
                              options={allLanguagesOptions}
                              value={marketplaceSecondaryLanguages}
                              onChange={(value: IDevelopmentSpecificationPropChange<string>[]) => secondaryLanguagesChange(value)}
                              {...register('secondaryLanguages')}
                            />
                          </Box>
                        </Box>
                        <Box flex="1" display="flex" gap="40px">
                          <Box mb={6} display="flex" flexDirection="column" width="100%">
                            <BoxFormAutocomplete
                              multiple
                              label={translations.developers}
                              name="developers"
                              options={developersOptions}
                              value={marketplaceDeveloper}
                              onChange={(value: IDevelopmentSpecificationPropChange<IDeveloper>[]) => developersChange(value)}
                              {...register('developers')}
                            />
                          </Box>
                        </Box>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.purchaseTerms)) }
                      </Box>
                      {marketplace && (
                        <DeveloperPropertyList
                          list={optionsList}
                          lang={marketplace?.language}
                          // onChange={ (data) => developerPropertyChange(data, `developmentSpecification.${ DeveloperPropertyType.purchaseTerms }`) }
                          {...register(`developmentSpecification.${DeveloperPropertyType.purchaseTerms}`)}
                        />
                      ) }
                    </TabPanel>
                    <Box display="flex" justifyContent="flex-end" gap="20px">
                      <ButtonApp
                        onClick={close}
                        text={translations.cancel}
                        color="primary"
                        variant="outlined"
                        type="button"
                        className={styles.btn}
                      />
                      <ButtonApp
                        text={translations.saveChanges}
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={styles.btn}
                        disabled={!formState.isValid}
                      />
                    </Box>
                  </TabContext>
                </form>
              </Box>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  { error }
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
