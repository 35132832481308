import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { AccordionStateType } from 'src/components/profileComponents/Marketplace/types';

interface CollapsibleFilterProps {
  title: string;
  children: React.ReactNode;
  classes: any;
  showSwitcher?: boolean;
  accordionState?: AccordionStateType | null;
  currency?: string;
  onCurrencyToggle?: (newCurrency: string) => void;
}
export const CollapsibleFilter: React.FC<CollapsibleFilterProps> = ({
  title,
  children,
  classes,
  showSwitcher = false,
  accordionState,
  currency,
  onCurrencyToggle,
}) => {
  const [isOpen, setIsOpen] = useState(accordionState && ['open', 'none'].includes(accordionState));
  const [currentCurrency, setCurrentCurrency] = useState(currency);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleCurrencyClick = (event: any, newCurrency: string) => {
    event.stopPropagation();
    setCurrentCurrency(newCurrency);
  };

  return (
    <div className={classes.collapsible}>
      <Box
        className={`${classes.collapsibleHeader} ${
          !isOpen ? 'hide' : ''
        }`}
        onClick={toggleOpen}
      >
        <h3 className={classes.collapsibleTitle}>
          {title}
          {(showSwitcher && (
            <div className={classes.switcher}>
              <button
                onClick={(e) => handleCurrencyClick(e, 'UAH')}
                className={currentCurrency === 'UAH' ? 'active' : ''}
                type="button"
              >
                грн
              </button>
              <button
                onClick={(e) => handleCurrencyClick(e, 'USD')}
                className={currentCurrency === 'USD' ? 'active' : ''}
                type="button"
              >
                $
              </button>
            </div>
          ))}
        </h3>
      </Box>
      <div
        className={`${classes.collapsibleContent} ${
          !isOpen ? 'hide' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};
