import { IMarketplaceFilterType } from 'src/components/profileComponents/Marketplace/types';

export enum ActionButtonsEnum {
  groupingFilter = 'GroupingFilter',
  tagAllValues = 'TagAllValues',
  tagSelectedValues = 'TagSelectedValues',
  checkboxAllValues = 'CheckboxAllValues',
  rangeAllValues = 'RangeAllValues',
}
export enum LocationButtonsEnum {
  elementsList = 'elementsList',
  locationGroup = 'locationGroup'
}
export const primarySelectionFilters = [
  IMarketplaceFilterType.GroupingFilter,
  IMarketplaceFilterType.AllValuesTag,
  IMarketplaceFilterType.AllValuesCheckbox,
  IMarketplaceFilterType.AllValuesRange];
export const primarySelectionTags = [IMarketplaceFilterType.SelectedValuesTag];
export const secondaryFilters = [
  IMarketplaceFilterType.GroupingFilter,
  IMarketplaceFilterType.AllValuesTag,
  IMarketplaceFilterType.SelectedValuesTag,
  IMarketplaceFilterType.AllValuesCheckbox,
  IMarketplaceFilterType.AllValuesRange];

export enum LocationFilterType {
  elementsList = 'NestedAllValuesTag',
  // locationGroup = 'TagAllValuesSubProperties2'
}
