import { Props } from 'react-input-mask';
import React from 'react';
import TextField from '@mui/material/TextField';
import { SvgTrash } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrash';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import Box from '@mui/material/Box';
import {
  IDeveloperPropertyType,
} from 'src/components/profileComponents/Developers/components/types';
import { ISpecificationProp } from 'src/typings/complexes';
import { useStyles } from './styles';

export const DeveloperPropertyItem: React.FC<{
  option: ISpecificationProp,
  lang: string,
  index: number,
  remove?: () => void,
  change?: (index: number, value: string, type?: IDeveloperPropertyType) => void}> = ({
    option, lang, index, remove, change,
  }) => {
    const styles = useStyles({});
    const hasOwnProperty = (propName: string): boolean => {
      return Object.prototype.hasOwnProperty.call(option, propName);
    };
    return (
      <div className={styles.itemWrapper}>
        { hasOwnProperty('prefix') && (
        <div className={styles.inputWrapper}>
          <TextField
            variant="outlined"
            defaultValue={option.prefix[lang]}
            onChange={(e) => change && change(index, e.target.value, 'prefix')}
          />
        </div>
        ) }
        <div className={styles.inputWrapper}>
          <TextField
            variant="outlined"
            defaultValue={option.name[lang]}
            onChange={(e) => change && change(index, e.target.value)}
          />
        </div>
        { hasOwnProperty('suffix') && (
        <div className={styles.inputWrapper}>
          <TextField
            variant="outlined"
            defaultValue={option.suffix[lang]}
            onChange={(e) => change && change(index, e.target.value, 'suffix')}
          />
        </div>
        ) }
        <div className={styles.actions}>
          <Box className={styles.action} onClick={() => remove && remove()}><SvgTrash width="20" height="20" /></Box>
          <div className={styles.action}><Drag /></div>
        </div>
      </div>
    );
  };
