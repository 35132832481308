import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';

export const rootUrl = 'https://dev1.flat.show';
export const serverPath = '/';
const API = '/api';
export const crosstableUrl = process.env.REACT_APP_REST_API + API;
export const crosstableWithoutApi = process.env.REACT_APP_REST_API;
export const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints;
export const allUtms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_nooverride', 'utm_referrer'];

class ApiUrlClass {
  complexId: string|number;

  constructor() {
    this.complexId = '';
  }

  getApiUrl() {
    const { complexId } = this;
    const buildType = getBuildType();
    const isCrossTable = buildType === BuildEnv.crossTable;
    return {
      apiUrl: crosstableUrl,
      base: `${rootUrl}/client/module/`,
      placementFilter: `get-filter-data?id=${complexId}&view=${isCrossTable ? 'crosstable' : 'module'}`,
      getComplexDefaultConfig: `get-complex-default-config?id=${complexId}`,
      getGroupedPlacementsConfig: `get-grouped-placements-data?id=${complexId}&view=${isCrossTable ? 'crosstable' : 'module'}`,
      getClientMenuModule: `get-client-module-menu?id=${complexId}`,
      getSpinnerViews: `get-views?id=${complexId}`,
      getSpinnerSvg: `get-spinners-svg?id=${complexId}`,
      getTranslations: `get-translates?id=${complexId}`,
      getInfrastructureFilter: `get-infrastructure-filter-data?id=${complexId}`,
      getDisplayPlacementData: `get-crosstable-display-placement-data-config?id=${complexId}`,
      getPlacementViewConfigByComplex: `${crosstableUrl}/placement_page_setups?complex=${complexId}&viewType=${isCrossTable ? 'crosstable' : 'module'}&isActive=true`,
      getPlacementViewConfig: `${crosstableUrl}/placement_page_setups`,
      getLoginCheck: `${crosstableUrl}/login_check`,
      getForgotPassword: `${crosstableUrl}/forgot-password`,
      getUserInfo: `${crosstableUrl}/user`,
      getPanoramaInfo: `${crosstableUrl}/panorama_v2s`,
      getComplexes: `${crosstableUrl}/complexes`,
      getMarketplaces: `${crosstableUrl}/marketplaces`,
      getMarketplacesFilters: `${crosstableUrl}/cntrlr/marketplace/filter`,
      getMarketplacesLanguageObjects: `${crosstableUrl}/cntrlr/marketplace/unique_json_language_objects`,
      getMarketplaceProperties: `${crosstableUrl}/marketplace_properties`,
      getHouses: `${crosstableUrl}/houses`,
      getHouseHistory: `${crosstableUrl}/house_excel_import_histories?house=`,
      getPlacementStatus: `${crosstableUrl}/placement_statuses`,
      getPlacement: `${crosstableUrl}/placements`,
      getUsersIdInfo: `${crosstableUrl}/users`,
      getAmoToken: `${crosstableUrl}/exchange-amo-token`,
      getDevelopInfo: `${crosstableUrl}/developers`,
      getDevelopSpecs: `${crosstableUrl}/developers__development_specifications`,
      getBitrixDeals: `${crosstableUrl}/bitrix_deals`,
      getBitrixDealData: `${crosstableUrl}/bitrix_deal_datas`,
      getBitrixProjects: `${crosstableUrl}/bitrix_projects`,
      getPlacementTypes: `${crosstableUrl}/placement_types?complex=${complexId}`,
      getBitrixCategories: `${crosstableUrl}/bitrix_categories`,
      getBitrixStages: `${crosstableUrl}/bitrix_stages`,
      bitrixDealClients: `${crosstableUrl}/bitrix_deal_clients`,
      bitrixFields: `${crosstableUrl}/bitrix_deal_placement_fields_linkings`,
      bitrixFormConfigurations: `${crosstableUrl}/bitrix_form_configurations`,
      bitrixFormConfigurationTimelines: `${crosstableUrl}/bitrix_form_configuration_timelines`,
      creatioProjects: `${crosstableUrl}/creatio_projects`,
      creatioStages: `${crosstableUrl}/creatio_stages`,
      creatioStagesCreateAuto: `${crosstableUrl}/creatio/stage/create-automatically`,
      creatioAutomatically: `${crosstableUrl}/create-automatically`,
      creatioPlacementStatusOnZeroDeals: `${crosstableUrl}/creatio_placement_status_on_zero_deals`,
      creatioOpportunities: `${crosstableUrl}/creatio_opportunities`,
      creatioPlacementOpporunities: `${crosstableUrl}/creatio/placement_opporunities`,
      creatioOpportunityMetadata: `${crosstableUrl}/creatio/opportunity_metadata`,
      creatioLeadMetadata: `${crosstableUrl}/creatio/lead_metadata`,
      creatioFields: `${crosstableUrl}/creatio_opportunity_placement_fields_matches`,
      creatioFormConfigurationMessages: `${crosstableUrl}/creatio_form_configuration_messages`,
      creatioFormConfiguration: `${crosstableUrl}/creatio_form_configurations`,
      netHuntCreateProject: `${crosstableUrl}/net_hunt_integrations`,
      netHuntDecodeUrl: `${crosstableUrl}/net_hunt/url_decoder`,
      netHuntListFolders: `${crosstableUrl}/net_hunt/list_folders`,
      netHuntFunnelFields: `${crosstableUrl}/net_hunt/list_folder_properties`,
      netHuntFunnel: `${crosstableUrl}/net_hunt_funnels`,
      netHuntStages: `${crosstableUrl}/net_hunt_funnel_stages`,
      netHuntEmptyOpportunityStatuses: `${crosstableUrl}/net_hunt_on_empty_opportunities_placement_statuses`,
      netHuntOpportunityPlacementMapping: `${crosstableUrl}/net_hunt_opportunity_placement_mappings`,
      netHuntPlacementPropertiesFields: `${crosstableUrl}/placement_properties/names_list`,
      placementProperties: `${crosstableUrl}/placement_properties?complex=${complexId}&pagination=false`,
      netHuntFeedbackFormFields: `${crosstableUrl}/website_form/key_names`,
      netHuntFeedbackFormConfiguration: `${crosstableUrl}/net_hunt_form_configurations`,
      netHuntFeedbackMessageConfiguration: `${crosstableUrl}/net_hunt_form_message_configurations`,
      bookNowForm: `${crosstableUrl}/module-form`,
      netHuntOpportunities: `${crosstableUrl}/net_hunt_funnel_opportunities`,
      netHuntPlacementOpporunities: `${crosstableUrl}/net_hunt/placement_opporunities`,
      importPlacements: `${crosstableUrl}/import/placements`,
      exportPlacements: `${crosstableUrl}/export/placements`,
      uploadFile: `${crosstableUrl}/files/upload`,
      languages: `${crosstableUrl}/cntrlr/geo_local/language_list`,
      countries: `${crosstableUrl}/cntrlr/geo_local/country_list`,
    };
  }

  setComplexId(id: number|string) {
    this.complexId = id;
  }
}

export const ApiUrl = new ApiUrlClass();
