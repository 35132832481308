import { httpService } from 'src/services';
import { ApiUrl } from 'src/constants';
import { HydraResponse, HydraView } from 'src/api/hydraResponse';
import { IMarketplace, IMarketplaceProperty } from 'src/components/profileComponents/Marketplace/types';
import {
  IMarketplaceFilterRequest,
  IMarketplaceFilterResponse,
  IMarketplaceLanguageObjects, IMarketplaceLanguageObjectsData,
} from 'src/typings/marketplace';

export function getMarketplaces(token: string = ''): Promise<HydraResponse<IMarketplace[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplacesByID(token: string = '', id: number): Promise<HydraResponse<IMarketplace>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceById(token: string = '', id: number): Promise<HydraResponse<IMarketplace[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function postMarketplace(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplaces}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function postMarketplaceFilter(token: string = '', data: IMarketplaceFilterRequest): Promise<IMarketplaceFilterResponse> {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplacesFilters}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function putMarketplace(token: string = '', id: number, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceLanguageObjects(token: string = '', data: IMarketplaceLanguageObjectsData): Promise<{data: IMarketplaceLanguageObjects}> {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplacesLanguageObjects}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceProperties(token: string = '', id: number): Promise<HydraResponse<IMarketplaceProperty[]>> {
  return new Promise((resolve) => {
    resolve(
      {
        config: { url: '' },
        headers: {},
        duration: 1,
        ok: true,
        originalError: null,
        problem: null,
        status: 0,
        data: {
          'hydra:totalItems': 1,
          'hydra:view': {
            '@id': '',
            '@type': '',
            'hydra:first': '',
            'hydra:last': '',
            'hydra:next': '',
          },
          'hydra:member': [
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.countryManual',
              translates: { uk: 'Країна' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.cityManual',
              translates: { uk: 'Місто' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.districtManual',
              translates: { uk: 'Вулиця' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroStationManual',
              translates: { uk: 'Станція метро' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroBranchManual',
              translates: { uk: 'Гілка метро' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroWalkMinutesManual',
              translates: { uk: 'Час ходу до станції' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroDriveMinutesManual',
              translates: { uk: 'Час їзди до станції' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'numeration',
              translates: { uk: 'Номер квартири' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'note',
              translates: { uk: 'Нотатка' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'tag',
              translates: { uk: 'Тег' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'rooms',
              translates: { uk: 'Кількість кімнат' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row0',
              translates: { uk: 'ров0' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row1',
              translates: { uk: 'ров1' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row2',
              translates: { uk: 'ров2' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row3',
              translates: { uk: 'ров3' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row4',
              translates: { uk: 'ров4' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row5',
              translates: { uk: 'ров5' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row6',
              translates: { uk: 'ров6' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row7',
              translates: { uk: 'ров7' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row8',
              translates: { uk: 'ров8' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row9',
              translates: { uk: 'ров9' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'planningType',
              translates: { uk: 'Тип планування' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementType.title',
              translates: { uk: 'Тип приміщення' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.name',
              translates: { uk: 'Статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.moduleName',
              translates: { uk: 'Модуль статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.crosstableName',
              translates: { uk: 'Шахматка статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'floors.numeration',
              translates: { uk: 'Поверх' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'section.numeration',
              translates: { uk: 'Секція' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.numeration',
              translates: { uk: 'Будинок' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.completionDates.name',
              translates: { uk: 'Дата завершення' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.purchaseTerms.name',
              translates: { uk: 'Умови купівлі' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.propertyClasses.name',
              translates: { uk: 'Клас житла' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.salesStatuses.name',
              translates: { uk: 'Статус продажів' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.constructionPhases.name',
              translates: { uk: 'Фаза побудови' },
            },
          ],
        },
      },
    );
    // return httpService.get(`${ApiUrl.getApiUrl().getMarketplaceProperties}/${id}`, {}, {
    //   Authorization: `Bearer ${token}`,
    //   'Content-Type': 'application/ld+json',
  });
}
