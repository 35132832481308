export const translations = {
  tagline: 'the best way to work with real estate online',
  errorInvalidEmail: 'You entered an invalid e-mail',
  errorRequired: 'This field is required',
  errorMin8: 'Password should consist of at least 8 characters',
  errorOnlyLatin: 'Password should consist of latin letters only',
  errorMatch: 'Passwords must match',
  email: 'Email',
  authTitle: 'Authorization',
  regSubmit: 'Sign up',
  logSubmit: 'Log in',
  regTitle: 'Sign up',
  logTitle: 'The entrance to the personal account',
  forgotLink: 'Forgot password?',
  password: 'Password',
  alternativeText: 'Or login with',
  regFooter: 'Already have an account?',
  logFooter: 'Don\'t have an account?',
  leaveToComplexes: 'Back to all projects',
  logOut: 'Log out',
  loadMore: 'Load more',
  loading: 'Loading',
  requiredField: 'This field required',
  invalidComplexShortName: 'Only english chars or numbers without special characters or spaces',

  // create project
  projectName: 'Project name',
  projectShortName: 'Project URL ending',
  projectAddress: 'Project address',
  projectCallBackCountryCode: 'Callback form country code',
  primaryLanguage: 'Primary language',
  systemPrimaryLanguage: 'System primary language',
  systemSecondaryLanguages: 'System secondary languages',
  secondaryLanguages: 'Secondary languages',
  adminPanelPrimaryLanguage: 'Admin panel primary language',
  adminPanelSecondaryLanguages: 'Admin panel secondary languages',
  websitePrimaryLanguage: 'Website primary language',
  websiteSecondaryLanguages: 'Website secondary languages',
  uploadProjectPreviewImage: 'Upload project preview image',
  uploadProjectLogo: 'Upload project logo',

  // project specification
  purchaseTerms: 'Purchase terms',
  propertyClass: 'Property class',
  salesStatus: 'Sales status',
  constructionPhase: 'Construction phase',
  completionDate: 'Completion date',

  //
  or: 'or',
  and: 'and',
  not: 'not',

  //
  restorePass: 'Restore password',
  varifyEmail: 'Verify email',
  confirmationCodeSentTo: 'A confirmation code was sent to',
  changePass: 'Change password',
  sendCode: 'Send code',
  resendCode: 'Re-send code',
  code: 'Code',
  newPass: 'New password',
  repeatNewPass: 'Repeat new password',
  resetPass: 'Reset password',

  photo: 'Photo',
  name: 'Name',
  role: 'Role',
  edit: 'Edit',
  set: 'Set',
  invite: 'Invite',
  title: 'Profile',
  logo: 'Logo',
  deactivate: 'Deactivate',
  complexesLink: 'Projects',
  project: 'Project',
  developer: 'Developer',
  managersLink: 'Managers',
  developersLink: 'Developers',
  marketplaceLink: 'Marketplaces',
  projectsLink: 'Your projects',
  createProject: 'Create a new project',
  projectEditing: 'Project editing',
  settingsLink: 'Admin settings',
  settings: 'Settings',
  managers: 'Managers',
  manager: 'Manager',
  admin: 'Admin',
  superadmin: 'Superadmin',
  supervisor: 'Supervisor',
  complexes: 'Complexes',
  statuses: 'Statuses',
  editStatuses: 'Edit statuses',
  addNewStatuses: 'Add a new status',
  logout: 'Logout',
  addProject: 'Add project',
  addDeveloper: 'Add developer',
  developerEditing: 'Developer editing',
  integration: 'Integration',
  mainData: 'Main data',
  dealDataAccessManagementHeader: 'Deal Data Access Management',
  dealDataAccessManagementForManagers: 'Show deal data for managers (deal, responsible, client\'s name and phone number)',
  domainName: 'Domain name',
  funnelSettings: 'Funnel settings',
  addManager: 'Add manager',
  addComplex: 'Add complex',
  addStage: 'Add stage',
  generateStages: 'Generate stages',
  stageName: 'Stage name',
  stageId: 'Stage ID',
  notFoundComplexes: 'No residential complexes found',
  notFoundDevelopers: 'No developers found',
  saveChanges: 'Save changes',
  decode: 'Decode',
  goToStorage: 'Stock & buildings management',
  manageStock: 'Manage stock',
  successfullyUpdated: 'Successfully Updated',
  partiallyUpdated: 'Partially Updated',
  massiveError: 'Massive Error',
  storage: 'Stock',
  house: 'House',
  building: 'Building',
  buildingEditing: 'Edit building information',
  section: 'Section',
  floor: 'Floor',
  unit: 'Unit',
  unitNumber: 'Unit number',
  totalArea: 'Total area',
  layoutType: 'Layout type',
  mistake: 'Mistake',
  property: 'Property',
  oldValue: 'Old value',
  newValue: 'New value',
  openLogs: 'Open Logs',
  rollUpLog: 'Roll up log',
  seeLog: 'Updates logs',
  errorList: 'Errors list',
  updatesList: 'Updates list',
  listNewProps: 'List of newly created properties',
  logErrorHead: 'Please, pay attention to the changes that caused the error(s) below.',
  import: 'Import updated database',
  export: 'Export current database',
  exportThisVersion: 'Archived version',
  goBack: 'Return',
  crmAdminStatus: 'CRM/Admin panel status',
  websiteStatus: 'Website status',
  typeAndPeriod: 'Type and period',
  replaceable: 'Replaceable',
  available: 'Availaible',
  managersSearchPlaceholder: 'Search by name or email',

  // manager page (not all ^)
  editManager: 'Manager\'s update',
  addingManager: 'Adding manager',
  selectManagers: 'Select from existing managers',
  noManagersFound: 'No managers found',
  add: 'Add',

  // status page
  colorInCross: 'Color in CRM/Admin panel',
  colorInWebsite: 'Color in website',
  nameInModule: 'Name in module',
  nameInCross: 'Name in crosstable',
  addingNewStatus: 'Adding new status',
  editingStatus: 'Editing status',
  type: 'Type',
  period: 'Period',
  hours: 'hours',
  managerCanChange: 'Manager can change',
  managerCanApply: 'Manager can apply',
  oneApartInDeal: 'One unit per deal',
  allowAttachUnitInDeal: 'Allow attach unit to the deal',
  canAttachUnitInDeal: 'Can attach to the deal',
  bindingRoomsWithStatus: 'Attach units with the status',

  // header
  changeView: 'Change view',
  simpleView: 'simple',
  listView: 'listing',
  detailedView: 'detailed',

  // appartment status
  chooseStatus: 'Choose a status',
  appartStatus: 'Status of the apartment',
  status: 'Status',
  validUntil: 'Valid until',
  applyNewStatus: 'Apply the new status',
  apply: 'Apply',

  // deals
  attachUnitToDeal: 'Attach the unit to the deal',
  detachUnitFromDeal: 'Detach the unit from the deal',
  openDealInCRM: 'Open a deal in CRM',
  deleting: 'Deleting',
  updating: 'Updating',
  responsible: 'Responsible',
  client: 'Client',
  cancel: 'Cancel',
  detach: 'Detach',
  otherAttachedDeals: 'Other attached deals',
  attachedDeals: 'Attached deals',
  disabledAttach: 'To attach this unit you need to detach another unit from this deal first',
  dialogTitle: 'Are you sure you want to detach the apartment',
  dialogContent: 'Before detaching, we recommend warning the user',

  // intergations
  attachedDealDetails: 'Attached deal details',
  responsibleUser: 'Responsible user',
  multipleResponsible: 'Multiple responsible',
  dealName: 'Deal name',

  // locations
  updateLocations: 'Update Location',
  latitude: 'Latitude',
  longitude: 'Longitude',
  countryManual: 'Country (manual)',
  cityManual: 'City (manual)',
  districtManual: 'District (manual)',
  nearestMetroStationManual: 'Nearest metro station (manual)',
  nearestMetroBranchManual: 'Station branch (manual)',
  nearestMetroWalkMinutesManual: 'Nearest station walk distance (manual)',
  nearestMetroDriveMinutesManual: 'Nearest station drive distance (manual)',
  countryAutoCalculated: 'Country (calculated)',
  cityAutoCalculated: 'City (calculated)',
  districtAutoCalculated: 'District (calculated)',
  nearestMetroStationAutoCalculated: 'Nearest metro station (calculated)',
  nearestMetroBranchAutoCalculated: 'Station branch (calculated)',
  nearestMetroWalkMinutesAutoCalculated: 'Nearest station walk distance (calculated)',
  nearestMetroDriveMinutesAutoCalculated: 'Nearest station drive distance (calculated)',

  // marketplace
  notFoundMarketplaces: 'No marketplaces found',
  marketplaceName: 'Marketplace Name',
  marketplaceShortName: 'Marketplace short Name',
  developers: 'Developers',
  addMarketplace: 'Create marketplace',
  marketplacesAndDevelopers: 'Marketplaces & developers',
  marketplaceEditing: 'Marketplace editing',
  marketplace: 'Marketplace',
  marketplaceSetUp: 'Marketplace set-up',
  marketplaceComponents: 'UI components display',
  projectsUnitToggle: 'Projects/Units Toggle',
  headerSettings: 'Header Settings',
  marketplaceLogo: 'Marketplace logo',
  headerTitle: 'Header title',
  favourites: 'Favourites',
  menu: 'Menu',

  // marketplace filters
  marketplaceFiltersLink: 'Filters',
  primarySelectionFilters: 'Primary selection filters',
  primarySelectionTags: 'Primary selection tags',
  secondaryFilters: 'Secondary filters',
  locationGroup: 'Location Group',
  elementsList: 'Elements List',
  selectElement: 'Select Element',
  selectContainer: 'Select Container',
  elementSortOrder: 'Element sort order',
  containerSortOrder: 'Container sort order',
  sortOrder: 'Sort Order',
  conversion: 'Conversion',
  addPrimaryTagGroup: 'Add another primary tag group',
  accordionState: 'Accordion state',
  callToAction: 'Call to action',
  chooseProperty: 'Choose property',
  chooseValue: 'Choose value',
  fromNaming: 'From naming',
  toNaming: 'To naming',
  mainUOM: 'Main UOM (unit of measurement)',
  secondaryUOM: 'Secondary UOM',
  conversionRate: 'Conversion rate',
  isNeedToChange: 'The previously selected value no longer exists. Please choose another value from the available options',
};
