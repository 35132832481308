import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  rangeWrapper: {
    display: 'flex',
    gap: '20px',
  },
  subRangeWrapper: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  subRangeSwitch: {},
}));
