import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dragBtn: {
    border: 'none',
    cursor: 'grab',
    display: 'flex',
    padding: '0',
    background: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
