import React from 'react';
import { GroupWrapperProps } from 'src/components/profileComponents/Marketplace/components/GroupWrapper/types';
import { SvgTrash } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrash';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

export const GroupWrapper: React.FC<GroupWrapperProps> = ({
  children, startIcon, name, useDrag = true, useTrash = true, onTrash,
}) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.groupWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.nameWrapper}>
            <div className={styles.icon}>{ startIcon }</div>
            <div className={styles.name}>{ name }</div>
          </div>
          <div className={styles.actionsWrapper}>
            {useTrash && (
            <Box
              className={styles.trash}
              onClick={onTrash}
            >
              <SvgTrash />
            </Box>
            ) }
            {useDrag && (
              <div className={styles.drag}><Drag /></div>
            ) }
          </div>
        </div>
      </div>
      <div className={styles.body}>
        { children }
      </div>
    </>
  );
};
