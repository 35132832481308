import React from 'react';
import { appIcon } from 'src/assets';
import { DragConfig } from './types';
import { useStyles } from './styles';

export const Drag: React.FC<{config?: DragConfig}> = ({ config }) => {
  const styles = useStyles();
  return (
    <button type="button" className={`${styles.dragBtn} drag-handle`}>
      <img src={config ? config.dragSrc : appIcon.iconDrag} alt="iconDrug" />
    </button>
  );
};
