import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  primarySelectionTags: {
    marginBottom: '42px',
  },
  actions: {
    display: 'flex',
    gap: '12px',
    marginBottom: '16px',
  },
  locationActions: {
    marginLeft: '16px',
    display: 'flex',
    gap: '16px',
    marginBottom: '16px',
  },
  filtersWrapper: {
    '& .section': {
      marginBottom: '40px',
      backgroundColor: '#fff',
    },
  },
  locationSection: {},
  tagSection: {},
  selectedTagSection: {},
  rangeSection: {},
  checkboxSection: {},
}));
