import React, { useCallback } from 'react';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { Box } from '@mui/material';
import { SvgUpload } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgUpload';
import { SvgTrashBig } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrashBig';
import { getFileWindow } from 'src/helpers/upload';
import { uploadFile } from 'src/api';
import { useGetAuthData } from 'src/hooks';
import { useStyles } from './styles';

interface IUploadSectionProps {
  register: any,
  registerName: string,
  image: string | null,
  onUpload?: (file: File, response: any) => void,
  onMoveToTrash?: () => void,
}
export const UploadSection: React.FC<IUploadSectionProps> = ({
  register, registerName, image, onUpload, onMoveToTrash,
}) => {
  const jwt = useGetAuthData().getJWT();
  const styles = useStyles({});

  const _onUpload = useCallback(() => {
    getFileWindow((file) => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadFile(jwt, formData).then(async (res: any) => {
          if (onUpload) onUpload(file, res);
        });
      }
    }, { accept: 'image/*,.jpg,.jpeg,.png,.svg,.webp' });
  }, []);

  return (
    <Box
      className={styles.uploadContent}
    >
      <BoxFormTextField
        name={registerName}
        inputRef={register}
        disabled
        style={{
          backgroundImage: `url("${image || ''}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: '4px',
        }}
        {...register(registerName)}
      />
      <div className={styles.uploadIconWrapper}>
        { !image && (
          <Box
            className={`${styles.uploadIcon} uploadIcon`}
            style={{
              opacity: image ? '.5' : '1',
            }}
            onClick={() => _onUpload()}
          >
            <SvgUpload />
          </Box>
        ) }
        { image && (
          <Box
            className={`${styles.uploadIcon} uploadIcon`}
            style={{
              opacity: image ? '.5' : '1',
            }}
            onClick={() => onMoveToTrash && onMoveToTrash()}
          >
            <SvgTrashBig />
          </Box>
        ) }
      </div>
    </Box>
  );
};
