import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IComplex } from 'src/typings/complexes';
import { getComplex } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/crosstable/actions';

import { getComplexData, getDeveloperData } from 'src/api';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';

export const useGetComplex = (id: string|undefined|null, saveToStore?: boolean) => {
  const [complex, setComplex] = useState<IComplex|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const savedComplex: IComplex|null = useSelector(getComplex);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchComplex = () => {
    if (id) {
      setIsLoading(true);
      getComplexData(jwt, id)
        .then((res: any) => {
          const { data } = res;
          setComplex(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if (id && (!savedComplex || Number(id) !== Number(savedComplex.id))) {
      setIsLoading(true);
      getComplexData(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setComplex(res.data);
            setIsLoading(false);
            if (saveToStore === undefined || saveToStore) {
              dispatch(Actions.setComplex(res.data));
            }
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (savedComplex) {
      setComplex(savedComplex);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [id]);

  return {
    isLoading,
    complex,
    fetchComplex,
  };
};
