import {
  call, put, takeEvery, select,
} from 'redux-saga/effects';
import { isArray } from 'lodash';
import country from 'country-list-js';

import { ActionTypes } from 'src/store/requests/filter/actions';
import { Actions as ActionsAppParams, ActionTypes as AppActionTypes } from 'src/store/app/actions';
import { Actions as ActionsFloorsParams } from 'src/store/floors/actions';
import { Actions as ActionsTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/actions';
import { Actions as ActionsRequestAppParams } from 'src/store/requests/app/actions';
import { Actions as ActionsFilterParams } from 'src/store/filter/filter-params/actions';
import { Actions as ActionsFilteredParams } from 'src/store/filter/filtered-data/actions';
import { Actions as ActionsTypePlacementParams } from 'src/store/requests/type-of-property/actions';
import { Actions as ActionsFilterValue } from 'src/store/filter/filter-value/actions';
import { Actions as ActionsView2d } from 'src/store/view-2d/actions';
import { Actions as ActionsSpinner } from 'src/store/spinner/actions';
import { IFilterData, IFilterParams } from 'src/typings/filter';
import { getDefaultPlacementType } from 'src/store/crosstable/selectors';
import { generateFilterInitialValue } from 'src/helpers';
import { localStorageConstants } from 'src/constants';
import {
  getComplexDefaultConfig,
} from 'src/api';
import { makeRequestSaga } from 'src/store/common';
import { UrlConfigure } from 'src/typings/app';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import ISO6391 from 'iso-639-1';

export function* getFilterDataRequestSaga(action: {
  type: string,
  payload: number,
}) {
  if (action.payload) {
    yield put(ActionsView2d.clearView2d());
    const getStorageItemsSelector: string | null = localStorage.getItem(localStorageConstants.favoriteList);
    const favIdsList: number = getStorageItemsSelector ? JSON.parse(getStorageItemsSelector)[action.payload]?.length : 0;
    let storageCurrentLanguage: string = '';

    const path = window.location.pathname;
    let locale = path.split('/')[1];
    const checkedLocale = ISO6391.getName(locale);
    const countryName = country.findByIso2(locale === 'uk' ? 'UA' : locale?.toUpperCase());
    if (checkedLocale || countryName) {
      locale = locale === 'ua' ? 'uk' : locale;
      storageCurrentLanguage = locale;
      yield put(ActionsAppParams.setCurrentLang(locale));
    }
    yield put(ActionsAppParams.setFavoriteCount(favIdsList));
    yield put(ActionsRequestAppParams.getAppDataRequest());

    yield call(
      makeRequestSaga,
      ActionsRequestAppParams.getAppDataSuccess,
      ActionsRequestAppParams.getAppDataFail,
      () => getComplexDefaultConfig(storageCurrentLanguage || ''),
    );
  }
}

export function* setFilterDataParams(action: {
  type: string,
  payload: IFilterData,
}) {
  const query = new URLSearchParams(document.location.search ? document.location.search.slice(1) : document.location.hash.slice(3));
  const listTypePlacement = Object.keys(action.payload);
  const defaultPlacementType: string | null = yield select(getDefaultPlacementType);
  const firstTypeSelected = defaultPlacementType || listTypePlacement[0];
  let typePlacementUrl = firstTypeSelected;

  if (query) {
    query.forEach((value, key) => {
      if (Object.values(UrlConfigure).includes(key as any)) {
        if (
          UrlConfigure.typePlacementUrl === key
          && listTypePlacement.includes(value)
        ) {
          typePlacementUrl = value;
        }
      }
    });
  }

  const filterInitialParameters = action.payload?.[typePlacementUrl]?.filter_default_values
    .reduce((accu, cur) => {
      return {
        ...accu,
        [cur.attribute]: {
          data: generateFilterInitialValue(cur.type),
          type: cur.type,
          isDisabled: false,
        },
      };
    }, {} as IFilterParams);

  const filteredKeys = listTypePlacement.reduce((accu: any, cur: string) => {
    return {
      ...accu,
      [cur]: [],
    };
  }, {});

  const replaceChars = (value: string) => {
    return value.replace(/%22/ig, '"').replace(/%20/ig, ' ');
  };

  if (query) {
    let collapsedFilter;
    let typeDisplayedSpinner;
    let floorData: string | undefined;
    let currentSpinnerName;
    let currentSpinnerId;
    let currentEntityName;
    let currentEntityId;

    query.forEach((value, key) => {
      const formatKey = key.toLocaleLowerCase();
      if (filterInitialParameters[key]) {
        if (isArray(filterInitialParameters[key].data)) {
          filterInitialParameters[key].data = value.split(';');
        } else {
          filterInitialParameters[key].data = value;
        }
      }

      Object.values(UrlConfigure).forEach((urlItem) => {
        if (urlItem.toLocaleLowerCase() === key.toLocaleLowerCase()) {
          Object.values(TypesOfSpinner).forEach((typesItem) => {
            if (typesItem.toLocaleLowerCase() === value.toLocaleLowerCase()) {
              typeDisplayedSpinner = typesItem;
            }
          });
        }
      });

      if (formatKey === UrlConfigure.curSpinnerName.toLocaleLowerCase()) currentSpinnerName = value;
      if (formatKey === UrlConfigure.curSpinnerId.toLocaleLowerCase()) currentSpinnerId = value;
      if (formatKey === UrlConfigure.curEntityName.toLocaleLowerCase()) currentEntityName = value;
      if (formatKey === UrlConfigure.curEntityId.toLocaleLowerCase()) currentEntityId = value;
      if (formatKey === UrlConfigure.floorData.toLocaleLowerCase()) floorData = replaceChars(value);
      if (formatKey === UrlConfigure.collapsedFilter.toLocaleLowerCase()) collapsedFilter = value;
    });
    if (typeDisplayedSpinner) yield put(ActionsTypeDisplayedSpinner.setTypeDisplayedSpinner(typeDisplayedSpinner));
    if (currentSpinnerName && currentSpinnerId) {
      yield put(ActionsSpinner.setCurrentSpinner({ name: currentSpinnerName, 'name-id': currentSpinnerId }));
    }
    if (currentEntityName && currentEntityId) {
      yield put(ActionsAppParams.setCurrentEntity({ name: currentEntityName, id: currentEntityId }));
    }
    if (floorData) {
      yield put(ActionsFloorsParams.setFloorReducer(JSON.parse(floorData)));
    }
    if (collapsedFilter) yield put(ActionsAppParams.isShowNestedFilter(collapsedFilter));

    // Clear URL address query parameters
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${window.location.hash}`,
    );
  }

  yield put(ActionsTypePlacementParams.setFilterTypePlacement(listTypePlacement));
  if (defaultPlacementType) {
    yield put(ActionsTypePlacementParams.changeSelectedTypePlacement(typePlacementUrl));
  } else {
    yield put(ActionsTypePlacementParams.setSelectedTypePlacement(typePlacementUrl));
  }

  if (filterInitialParameters) {
    yield put(ActionsFilterValue.setFilterValue(action.payload?.[typePlacementUrl].filter_default_values));
    yield put(ActionsFilterParams.setInitialFilterParameter(filterInitialParameters));

    yield put(ActionsFilteredParams.setFilteredKeys(filteredKeys)); // Set filter keys
    if (query) yield put(ActionsFilterParams.setFilterParameter());
  }
}

export function* getFilterWatchSaga() {
  yield takeEvery([
    AppActionTypes.SET_COMPLEX_ID,
  ], getFilterDataRequestSaga);
  yield takeEvery(ActionTypes.GET_FILTER_DATA_REQUEST_SUCCESS, setFilterDataParams);
}
