import { IAppState } from 'src/store/types';

export const getCurrentMarketplace = (state: IAppState) => state.marketplace?.currentMarketplace;
export const getMarketplaceLanguage = (state: IAppState) => state.marketplace?.language;
export const getMarketplaceInitialFilters = (state: IAppState) => state.marketplace?.filtersInitial;
export const getMarketplaceFilters = (state: IAppState) => state.marketplace?.filters;
export const getMarketplaceWhereConditions = (state: IAppState) => state.marketplace?.filterWhereConditions;
export const getMarketplaceComplexesTile = (state: IAppState) => state.marketplace?.complexesTile;
export const getMarketplacePlacementsTile = (state: IAppState) => state.marketplace?.placementsTile;
export const getMarketplacePrimarySectionTag = (state: IAppState) => state.marketplace?.currentMarketplace?.frontConfiguration.module.filtersConfiguration.primarySection.tag;
export const getMarketplaceActiveFilter = (state: IAppState) => state.marketplace?.activeFilter;
