import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  uploadContent: {
    zIndex: '1',
    position: 'relative',
    '&:hover': {
      '& .uploadIcon': {
        opacity: '1!important',
      },
    },
    '& .MuiInputBase-root': {
      height: '72px',
      width: '72px',
      border: '1px dashed #6C59E6',
      borderRadius: '8px',
      cursor: 'pointer',
      '&:hover fieldset': {
        cursor: 'pointer',
      },
      '& fieldset': {
        border: 'unset',
      },
      '& input': {
        display: 'none',
      },
    },
  },
  uploadIconWrapper: {
    position: 'absolute',
    right: '45%',
    bottom: '40%',
    display: 'flex',
    gap: '5px',
  },
  // uploadIcon: {
  //   cursor: 'pointer',
  //   color: '#6C59E6',
  //   borderRadius: '50%',
  //   backgroundColor: 'white',
  //   width: '35px',
  //   height: '35px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
}));
