import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
  },
  subGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '#fff',
  },
  subGroupWrapper: {
    width: '100%',
    height: '40px',
    borderLeft: '2px solid #6C59E6',
    background: '#F9E8FF',
    padding: '10px 16px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginBottom: 'auto',
  },
  name: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#6C59E6',
  },
  body: {
    borderLeft: '2px solid #6C59E6',
    paddingLeft: '16px',
    paddingTop: '8px',
  },
  offset: {
    width: '400px',
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .trash, .drag': {
      color: '#6C59E6',
    },
  },
  trash: {
    width: '20px',
    height: '20px',
    color: '#6C59E6',
    cursor: 'pointer',
  },
  drag: {},
}));
