import React, { useEffect, useState } from 'react';
import { DeveloperPropertyItem } from 'src/components/profileComponents/Developers/components/DeveloperPropertyItem';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import Box from '@mui/material/Box';
import { ISpecificationProp } from 'src/typings/complexes';
import { useStyles } from './styles';

interface PropertyListProps<T> {
  list: T[],
  lang: string,
  onChange?: (data: any) => void
}

export const DeveloperPropertyList = <T extends ISpecificationProp>({ list, onChange, lang }: PropertyListProps<T>) => {
  const [optionsList, setOptionsList] = useState<T[]>(list);
  const styles = useStyles({});

  useEffect(() => {
    setOptionsList(list);
  }, [list]);

  const remove = (index: number) => {
    let updatedList = [...optionsList];
    updatedList.splice(index, 1);
    updatedList = updatedList.map((item, updatedListIndex) => ({ ...item, sortOrder: updatedListIndex }));
    setOptionsList(updatedList);
    _onChange(updatedList);
  };

  const change = (index: number, value: string, type?: 'prefix' | 'suffix') => {
    const tempOptions = [...optionsList];
    if (type && tempOptions[index]?.[type]) {
      // @ts-ignore
      tempOptions[index][type][lang] = value;
    } else {
      tempOptions[index].name[lang] = value;
    }
    setOptionsList(tempOptions);
    _onChange(tempOptions);
  };

  const _onChange = (data: T[]) => {
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <ReactSortable<T>
      className={styles.developerPropertyWrapper}
      animation={150}
      handle=".drag-handle"
      list={optionsList.map((entity) => ({ ...entity, chosen: false }))}
      setList={(newList, _sortable: Sortable | null, store: Store) => {
        if (store && store.dragging && store.dragging.props
          && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
          const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
          setOptionsList(orderedList);
          _onChange(orderedList);
        }
      }}
    >

      { optionsList.map((option, index) => (
        <Box key={option.id}>
          <DeveloperPropertyItem
            option={option}
            lang={lang}
            index={index}
            change={change}
            remove={() => remove(index)}
          />
        </Box>
      )) }
    </ReactSortable>
  );
};
