import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import { IAppRequest } from 'src/typings/app';
import { Actions as AppActions } from 'src/store/app/actions';
import { getCurrentLang } from 'src/store/app/selectors';
import { makeRequestSaga } from 'src/store/common';
import { Actions as ActionsLayoutsParams } from 'src/store/requests/layouts/actions';
import {
  getClientModuleMenu,
  getGroupedPlacementsConfig,
  getInfrastructureData,
  getPlacementTypeFilter,
  getSpinnerInitData,
  getTranslationRequestData,
} from 'src/api';
import { Actions as ActionsSpinnerParams } from 'src/store/requests/spinner-views/actions';
import { Actions as ActionsTranslationsParams } from 'src/store/requests/translations/actions';
import { Actions as ActionsAppMenuParams } from 'src/store/requests/app-menu/actions';
import { Actions, ActionTypes as FilterActionTypes } from 'src/store/requests/filter/actions';
import { Actions as ActionsInfrastructureRequest } from 'src/store/requests/infrastructure/actions';
import ReactGA from 'react-ga';
import { ActionTypes } from './actions';

export function* setAppDataParams(action: {
  type: string,
  payload: IAppRequest,
}) {
  const isCrossTable = getBuildType() === BuildEnv.crossTable;
  const query: any = new URLSearchParams(document.location.search ? document.location.search.slice(1) : document.location.hash.slice(3));
  let globalCurrentLng: string = yield select(getCurrentLang);

  yield put(ActionsSpinnerParams.getSpinnerDataRequest());
  if (action.payload) {
    const {
      buttons_visibility_config,
      module_language,
      crosstable_language,
      crosstable_secondary_languages = [],
      module_secondary_languages = [],
      google_id,
    } = action.payload;
    const lang = isCrossTable ? crosstable_language : module_language;
    const secondaryLanguages = isCrossTable ? crosstable_secondary_languages : module_secondary_languages;
    const languagesList: string[] = [...secondaryLanguages, lang];

    if (!globalCurrentLng) {
      yield put(AppActions.setCurrentLang(lang));
      globalCurrentLng = lang;
    }

    // query.forEach((value: any, key: any) => {
    //   if (key === UrlConfigure.language && languagesList.includes(value)) {
    //     console.log('globalvalue', value);
    //     put(AppActions.setCurrentLang(value));
    //     globalCurrentLng = value;
    //   }
    // });

    if (google_id) {
      ReactGA.initialize(
        google_id,
        {
          debug: false,
        },
      );
    }

    yield put(AppActions.setAvailableLanguages(languagesList));
    yield put(AppActions.showInfrastructure(Boolean(buttons_visibility_config.infrastructure_active)));
    yield put(AppActions.isFilterOpen(isCrossTable ? false : !buttons_visibility_config.start_view_full_screen));
  }

  yield call(
    makeRequestSaga,
    ActionsLayoutsParams.getLayoutsSuccess,
    ActionsLayoutsParams.getLayoutsFail,
    () => getGroupedPlacementsConfig(globalCurrentLng),
  );
  yield call(
    makeRequestSaga,
    ActionsSpinnerParams.getSpinnerDataSuccess,
    ActionsSpinnerParams.getSpinnerDataFail,
    () => getSpinnerInitData(globalCurrentLng),
  );
  yield call(
    makeRequestSaga,
    ActionsTranslationsParams.getTranslationSuccess,
    ActionsTranslationsParams.getTranslationFail,
    () => getTranslationRequestData(globalCurrentLng),
  );

  yield call(
    makeRequestSaga,
    ActionsAppMenuParams.getAppClientMenuModuleSuccess,
    ActionsAppMenuParams.getAppClientMenuModuleSuccess,
    () => getClientModuleMenu(globalCurrentLng),
  );
  yield call(
    makeRequestSaga,
    Actions.getDataFilterSuccess,
    Actions.getDataFilterFail,
    () => getPlacementTypeFilter(globalCurrentLng),
  );
  yield call(
    makeRequestSaga,
    ActionsInfrastructureRequest.getInfrastructureDataSuccess,
    ActionsInfrastructureRequest.getInfrastructureDataFail,
    () => getInfrastructureData(globalCurrentLng),
  );
}

function* updateDataFilter() {
  const globalCurrentLng: string = yield select(getCurrentLang);

  yield call(
    makeRequestSaga,
    Actions.getDataFilterSuccess,
    Actions.getDataFilterFail,
    () => getPlacementTypeFilter(globalCurrentLng),
  );
}

export function* getAppWatchSaga() {
  yield takeEvery(FilterActionTypes.GET_FILTER_DATA_UPDATE, updateDataFilter);
  yield takeEvery(ActionTypes.GET_APP_DATA_REQUEST_SUCCESS, setAppDataParams);
}
