import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  filterHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    background: '#FFFFFF',
    border: '1px solid rgba(30, 30, 30, 0.1)',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    transition: 'background 0.5s',
    marginBottom: '20px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '& > *': {
      padding: '19px 32px',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      borderRadius: '8px',
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      cursor: 'pointer',
      transition: 'border 0.5s, box-shadow 0.5s, background-color 0.5s',

      '&:after': {
        content: '""',
        position: 'absolute',
        right: '-2px',
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: '1px',
        height: '48px',
        backgroundColor: '#e0e0e0',
        transition: 'opacity 0.5s',
      },

      '&:hover': {
        background: '#1E1E1E0D',

        transition: 'border 0.5s, box-shadow 0.5s, background 0.5s',

        '&:after': {
          opacity: 0,
          transition: 'opacity 0.5s',
        },
      },

      '&.show': {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        border: '1px solid rgba(30, 30, 30, 0.1)',
        transition:
          'border 0.5s, box-shadow 0.5s, backgroundColor 0.5s',

        '&:after': {
          opacity: 0,
          transition: 'opacity 0.5s',
        },

        '&:hover': {
          background: '#fff',
          transition: 'background 0.5s',
        },
      },

      '&:last-child': {
        '&:after': {
          display: 'none',
        },
        '& > :last-child': {
          left: 'initial',
          right: 0,
        },
      },
    },

    '&.show': {
      backgroundColor: '#1E1E1E0D',
      transition: 'background 0.5s',

      '& > *': {
        '&:hover': {
          background: '#1E1E1E0D',
          transition: 'background 0.5s',
        },
      },
    },
  },
  filterItem: {
    position: 'relative',
  },

  title: {
    margin: '0 0 8px 0',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000',
    fontWeight: '500',
  },
  subtitle: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#757575',
  },
}));
