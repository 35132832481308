export const setInputValue = <T>(prop: { propLink: T | null | undefined, propName: string}, value: string, lang: string) => {
  // @ts-ignore
  console.log('prop.propLink', prop.propLink[prop.propName], value);
  // @ts-ignore
  if (prop.propLink[prop.propName] === undefined || prop.propLink[prop.propName] === null) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    // prop.propLink = {};
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    prop.propLink[prop.propName] = { [lang]: value };
    return;
  }
  // @ts-ignore
  if (prop?.propLink[prop.propName] !== undefined || prop?.propLink[prop.propName] !== null) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    prop.propLink[prop.propName][lang] = value;
  }
};
