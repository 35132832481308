import React from 'react';
import { ButtonApp } from 'src/components/Button';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import AddIcon from '@mui/icons-material/Add';
import { IMarketplaceFilterType } from 'src/components/profileComponents/Marketplace/types';
import { useStyles } from './styles';

interface IActionButtonsProps {
  buttons?: IMarketplaceFilterType[];
  onClick?: (type: IMarketplaceFilterType) => void
}

export const ActionButtons: React.FC<IActionButtonsProps> = ({ buttons, onClick }) => {
  const styles = useStyles();
  return (
    <>
      {buttons && buttons.includes(IMarketplaceFilterType.GroupingFilter) && (
        <ButtonApp
          variant="outlined"
          className={styles.btn}
          startIcon={<FmdGoodOutlinedIcon />}
          onClick={() => onClick && onClick(IMarketplaceFilterType.GroupingFilter)}
        >
          Location
        </ButtonApp>
      ) }
      {buttons && buttons.includes(IMarketplaceFilterType.AllValuesTag) && (
        <ButtonApp
          variant="outlined"
          className={styles.btn}
          startIcon={<LabelOutlinedIcon />}
          onClick={() => onClick && onClick(IMarketplaceFilterType.AllValuesTag)}
        >
          Tag (all values)
        </ButtonApp>
      ) }
      {buttons && buttons.includes(IMarketplaceFilterType.SelectedValuesTag) && (
        <ButtonApp
          variant="outlined"
          className={styles.btn}
          startIcon={<AddIcon />}
          onClick={() => onClick && onClick(IMarketplaceFilterType.SelectedValuesTag)}
        >
          Tag group (selected values)
        </ButtonApp>
      ) }
      {buttons && buttons.includes(IMarketplaceFilterType.AllValuesCheckbox) && (
        <ButtonApp
          variant="outlined"
          className={styles.btn}
          startIcon={<FmdGoodOutlinedIcon />}
          onClick={() => onClick && onClick(IMarketplaceFilterType.AllValuesCheckbox)}
        >
          Checkbox (all values)
        </ButtonApp>
      ) }
      {buttons && buttons.includes(IMarketplaceFilterType.AllValuesRange) && (
        <ButtonApp
          variant="outlined"
          className={styles.btn}
          startIcon={<FmdGoodOutlinedIcon />}
          onClick={() => onClick && onClick(IMarketplaceFilterType.AllValuesRange)}
        >
          Range
        </ButtonApp>
      ) }
    </>
  );
};
