import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { SearchField } from 'src/components/SearchField';
import { useMarketplaces } from 'src/hooks/marketplace';
import { appIcon } from 'src/assets';
import { MarketplaceModal } from 'src/components/profileComponents/Marketplace/components/MarketplaceModal';
import { useHistory } from 'react-router-dom';
import { IMarketplace } from 'src/components/profileComponents/Marketplace/types';
import { useStyles } from './styles';

export const Marketplace: React.FC = () => {
  const { marketplaces, fetchMarketplaces } = useMarketplaces();
  const [modalOpen, setModalOpen] = useState(false);
  const [marketplaceEdit, setMarketplaceEdit] = useState<IMarketplace | undefined>();
  const styles = useStyles({});
  const [marketplacesList, setMarketplacesList] = useState<IMarketplace[]>([]);
  const history = useHistory();

  const toMarketPlacePage = (market: IMarketplace) => {
    history.push(`/profile/marketplaces/${market.id}`);
  };

  useEffect(() => {
    setMarketplacesList(marketplaces);
  }, [marketplaces]);

  const handleSearchMarketplace = useCallback((searchTerm: string) => {
    const search = searchTerm.toLowerCase();
    if (search) {
      setMarketplacesList(marketplacesList.filter((market) => {
        return market.name[market.language].toLowerCase().indexOf(search) >= 0;
      }));
    } else {
      setMarketplacesList(marketplaces);
    }
  }, [marketplacesList]);

  const handleAddMarketplace = () => {
    setModalOpen(true);
  };

  const handleChangeOpen = (isOpen: boolean) => {
    setModalOpen(isOpen);
    if (!isOpen) {
      setMarketplaceEdit(undefined);
    }
  };

  const handleEditMarketplace = () => {
    setModalOpen(true);
  };

  return (
    <Box py={8} px={4} className={styles.marketplacePage}>
      <Box mb={8} display="flex" flexWrap="nowrap">
        <Box fontSize="32px" fontWeight={700} mr="auto">
          { translations.marketplaceLink }
        </Box>
        <Box>
          <ButtonApp
            text={translations.addMarketplace}
            onClick={handleAddMarketplace}
            color="primary"
            variant="contained"
            type="submit"
            className={styles.btn}
          />
        </Box>
      </Box>
      <Box pb={8} display="flex" flexWrap="nowrap" className={styles.borderBottom}>
        <Box className={styles.searchHeader}>
          {translations.marketplacesAndDevelopers}
        </Box>
        <Box width={340}>
          <SearchField
            handleChange={handleSearchMarketplace}
            placeholder={translations.marketplace}
          />
        </Box>
      </Box>
      {marketplaces.length ? (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          { marketplacesList.map((marketplace) => (
            <Box
              pt="48px"
              pb="48px"
              key={marketplace.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              height="100%"
              className={styles.borderBottom}
            >
              <div>
                Marketplace name:
                { ` ${marketplace.name[marketplace.language]}` }
                <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                  { marketplace.developers.map((developer) => (
                    <Box key={developer.id}>{developer.name[developer.language]}</Box>
                  )) }
                </Box>
              </div>
              <Box>
                <Box display="flex" gap="20px">
                  <ButtonApp
                    text={translations.set}
                    color="secondary"
                    variant="outlined"
                    className={styles.actionBtn}
                    onClick={() => toMarketPlacePage(marketplace)}
                    startIcon={<img src={appIcon.iconGear.default} alt="Set marketplace" />}
                  />
                  <ButtonApp
                    text={translations.edit}
                    color="secondary"
                    variant="outlined"
                    className={styles.actionBtn}
                    onClick={() => {
                      setMarketplaceEdit(marketplace);
                      handleEditMarketplace();
                    }}
                    startIcon={<img src={appIcon.iconEdit.default} alt="edit marketplace" />}
                  />
                </Box>
              </Box>
            </Box>
          )) }
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          {translations.notFoundMarketplaces}
        </Box>
      )}
      {modalOpen && (
        <MarketplaceModal
          isOpen={modalOpen}
          handleChangeOpen={handleChangeOpen}
          refreshMarketplaces={fetchMarketplaces}
          marketplace={marketplaceEdit}
        />
      )}
    </Box>
  );
};
