import React from 'react';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import AddIcon from '@mui/icons-material/Add';
import {
  LocationButtonsEnum,
} from 'src/components/profileComponents/Marketplace/components/Filters/constants';
import { useStyles } from './styles';

interface ILocationButtonsProps {
  onClick?: (type: LocationButtonsEnum) => void
}

export const LocationButtons: React.FC<ILocationButtonsProps> = ({ onClick }) => {
  const styles = useStyles();
  return (
    <div className={styles.locationButtons}>
      <ButtonApp
        variant="outlined"
        className={styles.btn}
        startIcon={<AddIcon />}
        onClick={() => onClick && onClick(LocationButtonsEnum.locationGroup)}
      >
        {translations.locationGroup}
      </ButtonApp>
      <ButtonApp
        variant="outlined"
        className={styles.btn}
        startIcon={<AddIcon />}
        onClick={() => onClick && onClick(LocationButtonsEnum.elementsList)}
      >
        {translations.elementsList}
      </ButtonApp>
    </div>
  );
};
