import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { IStatus, IComplex, TypeOfStatus } from 'src/typings/complexes';
import { TypeOfRole } from 'src/typings/user';
import { useGetComplexStatuses, useGetAuthData } from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { patchPlacementStatus } from 'src/api';
import { decOfNum } from 'src/helpers';
import { appIcon } from 'src/assets';
import { borderColor } from 'src/constants/styles';
import { StatusModal } from './StatusModal';
import { useStyles } from '../styles';

interface IComplexStatuses {
  complex: IComplex;
}

export const ComplexStatuses: React.FC<IComplexStatuses> = ({ complex }) => {
  const styles = useStyles({});
  const { id }: { id: string } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [editedStatus, setEditedStatus] = useState<IStatus|null>(null);
  const { statuses, fetchStatuses, isLoading } = useGetComplexStatuses(id);
  const user = useSelector(requestSelectors.user.getUserData);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const handleEditStatus = (status: IStatus) => {
    setEditedStatus(status);
    setModalOpen(true);
  };
  const handleAddStatus = () => {
    setEditedStatus(null);
    setModalOpen(true);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, statusId: number) => {
    if (user?.role?.['@id'] !== TypeOfRole.manager) {
      patchPlacementStatus(jwt, statusId, {
        [e.target.name]: e.target.checked,
      });
    }
  };

  return (
    <Box>
      <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box fontSize="24px" fontWeight={600}>
          {translations.statuses}
        </Box>
        <ButtonApp
          text={translations.addNewStatuses}
          color="primary"
          variant="contained"
          className={styles.btn}
          onClick={handleAddStatus}
        />
      </Box>
      <Box overflow="auto" width="100%">
        <Box
          display="inline-flex"
          justifyContent="flex-start"
          alignItems="center"
          pb={1}
          fontSize="14px"
          borderBottom={`1px solid ${borderColor}`}
        >
          <Box width="50px" />
          <Box width="191px" pr={2}>
            {translations.crmAdminStatus}
          </Box>
          <Box width="171px" pr={2}>
            {translations.websiteStatus}
          </Box>
          <Box width="158px" pr={2}>
            {translations.canAttachUnitInDeal}
          </Box>
        </Box>
        {statuses && statuses.map((item) => (
          <Box
            display="inline-flex"
            justifyContent="flex-start"
            alignItems="center"
            key={item.id}
            mt={2}
            width="100%"
          >
            <Box width="50px">
              <IconButton color="primary" onClick={() => handleEditStatus(item)} size="large">
                <img src={appIcon.iconEdit.default} alt="edit statuses" />
              </IconButton>
            </Box>
            <Box
              display="flex"
              width="191px"
              pr={2}
              alignItems="center"
              fontSize="16px"
              lineHeight="20px"
              fontWeight={600}
            >
              <Box
                width="20px"
                height="20px"
                mr={1}
                borderRadius="5px"
                title={translations.colorInCross}
                style={{
                  backgroundColor: item.crosstableColor || '#fff',
                }}
              />
              <Box flex="1">
                {item.name && item.name[complex.language]}
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width="170px"
              pr={2}
              fontSize="16px"
              lineHeight="20px"
              fontWeight={600}
            >
              <Box
                width="20px"
                height="20px"
                mr={1}
                borderRadius="5px"
                title={translations.colorInWebsite}
                style={{
                  backgroundColor: item.color,
                }}
              />
              <Box flex="1">
                {item.module_name[complex.language]}
              </Box>
            </Box>
            <Box width="152px" pr={2}>
              <Checkbox
                onChange={(e) => handleCheckbox(e, item.id)}
                style={user?.role?.['@id'] === TypeOfRole.manager ? {
                  pointerEvents: 'none',
                } : {}}
                name="amoCanBeSetForDeal"
                color="primary"
                defaultChecked={item.amoCanBeSetForDeal}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {modalOpen && (
        <StatusModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshStatuses={fetchStatuses}
          status={editedStatus}
          complex={complex}
        />
      )}
    </Box>
  );
};
