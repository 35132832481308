import { action } from 'src/store/common';
import {
  IMarketplace,
  IMarketplaceProperty,
  IMarketplaceRequestFilter,
} from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceFilterResponse, IMarketplaceFilterResponseFilter } from 'src/typings/marketplace';
import { IComplex } from 'src/typings/complexes';
import { IPlacement } from 'src/store/placement/reducer';

export const ActionTypes = {
  SET_MARKETPLACE: '[MARKETPLACE]SET_MARKETPLACE',
  SET_MARKETPLACE_LANGUAGE: '[MARKETPLACE]SET_MARKETPLACE_LANGUAGE',
  SET_MARKETPLACE_FILTERS_INITIAL: '[MARKETPLACE]SET_MARKETPLACE_FILTERS_INITIAL',
  SET_MARKETPLACE_FILTERS: '[MARKETPLACE]SET_MARKETPLACE_FILTERS',
  SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS: '[MARKETPLACE]SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS',
  SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS: '[MARKETPLACE]SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS',
  SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS: '[MARKETPLACE]SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS',
  SET_MARKETPLACE_COMPLEXES_TILE: '[MARKETPLACE]SET_MARKETPLACE_COMPLEXES_TILE',
  SET_MARKETPLACE_PLACEMENTS_TILE: '[MARKETPLACE]SET_MARKETPLACE_PLACEMENTS_TILE',
  SET_MARKETPLACE_ACTIVE_FILTER: '[MARKETPLACE]SET_MARKETPLACE_ACTIVE_FILTER',
};

export const Actions = {
  setCurrentMarketplace: action<IMarketplace | undefined>(ActionTypes.SET_MARKETPLACE),
  setMarketplaceLanguage: action<string | undefined>(ActionTypes.SET_MARKETPLACE_LANGUAGE),
  setMarketplaceFiltersInitial: action<IMarketplaceFilterResponseFilter | undefined>(ActionTypes.SET_MARKETPLACE_FILTERS_INITIAL),
  setMarketplaceFilters: action<IMarketplaceFilterResponseFilter | undefined>(ActionTypes.SET_MARKETPLACE_FILTERS),
  setMarketplaceWhereConditions: action<IMarketplaceRequestFilter[] | undefined>(ActionTypes.SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS),
  setMarketplaceResetWhereConditions: action<string[] | undefined>(ActionTypes.SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS),
  setMarketplaceResetAllWhereConditions: action<void>(ActionTypes.SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS),
  setMarketplaceComplexesTile: action<IComplex[] | undefined>(ActionTypes.SET_MARKETPLACE_COMPLEXES_TILE),
  setMarketplacePlacementsTile: action<IPlacement[] | undefined>(ActionTypes.SET_MARKETPLACE_PLACEMENTS_TILE),
  setMarketplaceActiveFilter: action<string>(ActionTypes.SET_MARKETPLACE_ACTIVE_FILTER),
};
