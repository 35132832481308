import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { pathList } from 'src/containers/Profile/routeList';
import { useParams } from 'react-router-dom';
import { useMarketplaces } from 'src/hooks/marketplace';
import {
  ActionButtons,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/ActionButtons';
import {
  LocationGroup,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/LocationGroup';
import {
  LocationButtons,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/LocationGroup/components/LocationButtons';
import { SubGroupWrapper } from 'src/components/profileComponents/Marketplace/components/SubGroupWrapper';
import {
  LocationSubGroupElements,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/LocationSubGroupElements';
import {
  LocationSubGroupLocationGroup,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/LocationSubGroupLocationGroup';
import { TagAllValues } from 'src/components/profileComponents/Marketplace/components/Filters/components/Tag';
import { Range } from 'src/components/profileComponents/Marketplace/components/Filters/components/Range';
import { TagFilter } from 'src/components/profileComponents/Marketplace/components/Filters/components/TagFilter';
import {
  PrimaryTagFilter,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/PrimaryTagFilter';
import {
  LocationButtonsEnum,
  primarySelectionFilters,
  primarySelectionTags,
  secondaryFilters,
} from 'src/components/profileComponents/Marketplace/components/Filters/constants';
import {
  IMarketplace,
  IMarketplaceFilterLocation,
  IMarketplaceFilterRange,
  IMarketplaceFilterTagAllValues, IMarketplaceFilterTagSelectedValues,
  IMarketplaceFilterTagSelectedValueSelectedProperty,
  IMarketplaceFilterType,
  IMarketplaceModuleFiltersConfiguration, IMarketplaceModulePrimarySectionType,
  IMarketplaceProperty,
} from 'src/components/profileComponents/Marketplace/types';
import { RandomInteger } from 'src/helpers/randomInteger';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ButtonApp } from 'src/components/Button';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import { postMarketplace, putMarketplace } from 'src/api/marketplace';
import { useGetAuthData } from 'src/hooks';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Checkbox } from 'src/components/profileComponents/Marketplace/components/Filters/components/Checkbox';
import { IMarketplaceLanguageObjects } from 'src/typings/marketplace';
import { useStyles } from './styles';

export const Filters: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const {
    getMarketplace, marketplaceProperties, fetchMarketplaces, fetchMarketplaceLanguageObjects, requestMarketplaceLanguageObjects,
  } = useMarketplaces();
  const marketplace = getMarketplace(Number(id));
  const [primarySectionFilters, setPrimarySectionFilters] = useState<IMarketplaceModuleFiltersConfiguration | undefined>();
  const [marketplacePropertyOptions, setMarketplacePropertyOptions] = useState<IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]>([]);
  const [allSelectedProperties, setAllSelectedProperties] = useState<IMarketplaceLanguageObjects>();
  const [locationMarketplacePropertyOptions, setLocationMarketplacePropertyOptions] = useState<IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]>([]);
  const [error, setError] = useState('');
  const styles = useStyles();

  const {
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape({})),
    mode: 'onChange',
  });

  useEffect(() => {
    if (marketplace) {
      setPrimarySectionFilters(marketplace?.frontConfiguration.module.filtersConfiguration);
      const allSystemNames = marketplace?.frontConfiguration.module.filtersConfiguration.primarySection.tag.selectedProperties
        .map((item) => item.property?.systemName || '')
        .filter((systemName) => systemName.length);
      requestMarketplaceLanguageObjects({
        marketplaceId: marketplace.id,
        findProperties: allSystemNames,
      }).then((res) => {
        setAllSelectedProperties(res.data);
      });
    }
  }, [marketplace]);

  useEffect(() => {
    if (marketplaceProperties && marketplace) {
      const locationsProperties = marketplace.placementProperties.filter((item) => item.systemName.startsWith('complex.location'));
      const propertyOptions = locationsProperties.map((item) => ({ label: item.translates?.[marketplace.language] || item.systemName, value: item }));
      setMarketplacePropertyOptions(marketplace.placementProperties.map((item) => ({ label: item.translates?.[marketplace.language] || item.systemName, value: item })));
      setLocationMarketplacePropertyOptions(propertyOptions);
    }
  }, [marketplaceProperties, marketplace]);

  const primarySelectionFiltersOnClick = (type: IMarketplaceFilterType) => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      temp.primarySection.selections.push({
        id: RandomInteger(),
        type,
        sortOrder: temp.primarySection.selections.length + 1,
        property: marketplacePropertyOptions?.[0]?.value || null,
        name: { [marketplace?.language]: '' },
        callToActionText: { [marketplace?.language]: '' },
        accordionState: 'none',
        isAccordionOpen: true,
        filters: [],
      });
      setPrimarySectionFilters({ ...temp });
    }
  };

  const secondarySelectionFiltersOnClick = (type: IMarketplaceFilterType) => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      const value: IMarketplaceModulePrimarySectionType = {
        id: RandomInteger(),
        type,
        sortOrder: temp.secondaries.length + 1,
        name: { [marketplace?.language]: '' },
        callToActionText: { [marketplace?.language]: '' },
        accordionState: 'none',
        isAccordionOpen: true,
        filters: [],
      };
      if (type === IMarketplaceFilterType.SelectedValuesTag) {
        (value as unknown as IMarketplaceFilterTagSelectedValues).selectedProperties = [];
      }
      temp.secondaries.push(value);
      setPrimarySectionFilters({ ...temp });
    }
  };

  const primarySelectionTagsOnClick = () => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      temp.primarySection.tag.selectedProperties.push({
        id: RandomInteger(),
        sortOrder: temp.primarySection.tag.selectedProperties.length + 1,
        property: {
          systemName: '',
          translates: { [marketplace?.language]: '' },
        },
        value: { [marketplace?.language]: '' },
        iconFileUri: null,
      });
      setPrimarySectionFilters({ ...temp });
    }
  };

  const primarySelectedTagOnClick = (index: number) => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      (temp.secondaries[index] as IMarketplaceFilterTagSelectedValues).selectedProperties.push({
        id: RandomInteger(),
        sortOrder: (temp.secondaries[index] as IMarketplaceFilterTagSelectedValues).selectedProperties.length + 1,
        property: {
          systemName: '',
          translates: { [marketplace?.language]: '' },
        },
        value: { [marketplace?.language]: '' },
        iconFileUri: null,
      });
      setPrimarySectionFilters({ ...temp });
    }
  };

  const removeLocationFieldByIndex = (index: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      tmp.primarySection.selections.splice(index, 1);
      setPrimarySectionFilters(tmp);
    }
  };

  const removeSecondaryFieldByIndex = (index: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      tmp.secondaries.splice(index, 1);
      setPrimarySectionFilters(tmp);
    }
  };

  const removeLocationSubFieldByIndex = (index: number, subIndex: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      (tmp.primarySection.selections as IMarketplaceFilterLocation[])[index].filters.splice(subIndex, 1);
      setPrimarySectionFilters(tmp);
    }
  };

  const removeSecondaryLocationSubFieldByIndex = (index: number, subIndex: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      (tmp.secondaries as IMarketplaceFilterLocation[])[index].filters.splice(subIndex, 1);
      setPrimarySectionFilters(tmp);
    }
  };

  const removePrimarySectionTagByIndex = useCallback((index: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      tmp.primarySection.tag.selectedProperties.splice(index, 1);
      setPrimarySectionFilters(tmp);
    }
  }, [primarySectionFilters]);

  const removePrimarySelectedTagByIndex = useCallback((index: number, subIndex: number) => {
    if (primarySectionFilters) {
      const tmp = { ...primarySectionFilters };
      (tmp.secondaries[index] as IMarketplaceFilterTagSelectedValues).selectedProperties.splice(subIndex, 1);
      setPrimarySectionFilters(tmp);
    }
  }, [primarySectionFilters]);

  const isNeedToBeUpdated = useCallback((property: IMarketplaceFilterTagSelectedValueSelectedProperty): boolean => {
    if (!allSelectedProperties || !Object.keys(allSelectedProperties).length || !marketplace || !property.property?.systemName) return false;
    return !!allSelectedProperties[property.property.systemName]?.length && !allSelectedProperties[property.property.systemName]
      .find((prop) => JSON.stringify(prop) === JSON.stringify({ [marketplace.language]: property.value[marketplace.language] }));
  }, [marketplace, allSelectedProperties]);

  const locationButtonOnClick = (type: LocationButtonsEnum, index: number) => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      (temp.primarySection.selections[index] as IMarketplaceFilterLocation).filters.push({
        id: RandomInteger(),
        type: IMarketplaceFilterType.NestedAllValuesTag,
        subType: type,
        sortOrder: (temp.primarySection.selections[index] as IMarketplaceFilterLocation).filters.length + 1,
        name: { [marketplace?.language]: '' },
        callToActionText: null,
        accordionState: 'none',
        propertyValuesOrder: 'A-Z_0-9',
        property: null,
        subFilters: type === LocationButtonsEnum.elementsList ? [] : [{
          id: RandomInteger(),
          type: IMarketplaceFilterType.NestedAllValuesTag,
          subType: type,
          sortOrder: 0,
          name: { [marketplace?.language]: '' },
          callToActionText: null,
          accordionState: 'none',
          propertyValuesOrder: 'A-Z_0-9',
          property: null,
          subFilters: [],
        }],
      });
      setPrimarySectionFilters({ ...temp });
    }
  };

  const secondaryLocationButtonOnClick = (type: LocationButtonsEnum, index: number) => {
    if (primarySectionFilters && marketplace) {
      const temp = { ...primarySectionFilters };
      (temp.secondaries[index] as IMarketplaceFilterLocation).filters.push({
        id: RandomInteger(),
        type: IMarketplaceFilterType.NestedAllValuesTag,
        subType: type,
        sortOrder: (temp.secondaries[index] as IMarketplaceFilterLocation).filters.length + 1,
        name: { [marketplace?.language]: '' },
        callToActionText: null,
        accordionState: 'none',
        propertyValuesOrder: 'A-Z_0-9',
        property: null,
        subFilters: type === LocationButtonsEnum.elementsList ? [] : [{
          id: RandomInteger(),
          type: IMarketplaceFilterType.NestedAllValuesTag,
          subType: type,
          sortOrder: 0,
          name: { [marketplace?.language]: '' },
          callToActionText: null,
          accordionState: 'none',
          propertyValuesOrder: 'A-Z_0-9',
          property: null,
          subFilters: [],
        }],
      });
      setPrimarySectionFilters({ ...temp });
    }
  };

  const onSave = async () => {
    if (primarySectionFilters && marketplace) {
      const saveData: IMarketplace = {
        ...marketplace,
        frontConfiguration: {
          ...marketplace.frontConfiguration,
          module: {
            ...marketplace.frontConfiguration.module,
            filtersConfiguration: {
              // ...marketplace.frontConfiguration.module.filtersConfiguration,
              ...primarySectionFilters,
            },
          },
        },
      };
      let res;

      if (marketplace) {
        res = await putMarketplace(jwt, marketplace.id, saveData);
      } else {
        res = await postMarketplace(jwt, primarySectionFilters);
      }

      if (res.ok) {
        fetchMarketplaces();
      } else {
        setError(res.data['hydra:description']);
      }
    }
  };

  const crumbs: ICrumbs[] = [
    {
      name: translations.marketplace,
      url: `/profile/${pathList.marketplace}`,
    },
    {
      name: `${marketplace?.name[marketplace?.language]} set-up`,
      url: `/profile/${pathList.marketplace}/${marketplace?.id}`,
    },
    {
      name: 'Filters',
    },
  ];
  return (
    <Box py={5} px={4}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box mb={8} display="flex" flexWrap="nowrap">
          <Box fontSize="32px" mb={1} fontWeight={700} mr="auto">
            { translations.marketplaceComponents }
          </Box>
          <Box>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
            />
          </Box>
        </Box>
        <Box mb={5}>
          <Breadcrumbs crumbs={crumbs} />
        </Box>
        <Box className={styles.filtersWrapper}>
          <div className="primary-selection-silters">
            <Box fontSize="24px" mb={8} fontWeight={700}>
              { translations.primarySelectionFilters }
            </Box>
            <div className={styles.actions}>
              <ActionButtons
                buttons={primarySelectionFilters}
                onClick={primarySelectionFiltersOnClick}
              />
            </div>
            {primarySectionFilters && (
              <ReactSortable
                animation={150}
                handle=".drag-handle"
                list={primarySectionFilters?.primarySection.selections.map((entity) => ({ ...entity, chosen: false }))}
                setList={(newList, sortable: Sortable | null, store: Store) => {
                  if (store && store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                    const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                    setPrimarySectionFilters({
                      ...primarySectionFilters,
                      primarySection: {
                        ...primarySectionFilters.primarySection,
                        selections: orderedList,
                        tag: primarySectionFilters?.primarySection.tag,
                      },
                      secondaries: [...primarySectionFilters?.secondaries],
                    });
                  }
                }}
              >
                <>
                  { primarySectionFilters?.primarySection.selections.map((item, index) => {
                    if (item.type === IMarketplaceFilterType.GroupingFilter) {
                      return (
                        <div key={item.id} className={`${styles.locationSection} section`}>
                          <LocationGroup
                            entity={{ item }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeLocationFieldByIndex(index)}
                          />
                          <div className={styles.locationActions}>
                            <LocationButtons onClick={(type) => locationButtonOnClick(type, index)} />
                          </div>

                          <Box display="flex" flexDirection="column" gap={8}>
                            <ReactSortable
                              animation={150}
                              handle=".drag-handle"
                              list={(item as IMarketplaceFilterLocation).filters.map((entity) => ({ ...entity, chosen: false }))}
                              setList={(newList, sortable: Sortable | null, store: Store) => {
                                if (store && store.dragging && store.dragging.props
                                  && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                                  const temp = { ...item, filters: newList.map((listItem, listItemIndex) => ({ ...listItem, sortOrder: listItemIndex })) };
                                  const tempIndex = primarySectionFilters?.primarySection.selections.findIndex((section) => section.id === item.id);
                                  primarySectionFilters?.primarySection.selections.splice(tempIndex, 1, temp);
                                  setPrimarySectionFilters({
                                    ...primarySectionFilters,
                                    primarySection: {
                                      ...primarySectionFilters.primarySection,
                                      selections: [...primarySectionFilters?.primarySection.selections],
                                      tag: primarySectionFilters?.primarySection.tag,
                                    },
                                    secondaries: [...primarySectionFilters?.secondaries],
                                  });
                                }
                              }}
                            >
                              { (item as IMarketplaceFilterLocation).filters.map((filter, subIndex) => {
                                return (
                                  <div key={filter.id}>
                                    { !filter.subFilters.length && filter.type === IMarketplaceFilterType.NestedAllValuesTag && (
                                    <SubGroupWrapper
                                      name="Location: Elements list"
                                      onTrash={() => removeLocationSubFieldByIndex(index, subIndex)}
                                    >
                                      <LocationSubGroupElements
                                        entity={{ item: filter }}
                                        lang={marketplace?.language || ''}
                                        locationOptions={locationMarketplacePropertyOptions}
                                      />
                                    </SubGroupWrapper>
                                    ) }
                                    { !!filter.subFilters.length && (filter?.subType === LocationButtonsEnum.locationGroup || filter.type === IMarketplaceFilterType.NestedAllValuesTag) && (
                                    <SubGroupWrapper name="Location: Location group">
                                      <LocationSubGroupLocationGroup
                                        entity={{ item: filter }}
                                        lang={marketplace?.language || ''}
                                        locationOptions={locationMarketplacePropertyOptions}
                                      />
                                    </SubGroupWrapper>
                                    ) }
                                  </div>
                                );
                              }) }
                            </ReactSortable>
                          </Box>
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesTag) {
                      return (
                        <div key={item.id} className={`${styles.tagSection} section`}>
                          <TagAllValues
                            entity={{ item: (item as IMarketplaceFilterTagAllValues) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeLocationFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesCheckbox) {
                      return (
                        <div key={item.id} className={`${styles.checkboxSection} section`}>
                          <Checkbox
                            entity={{ item: (item as IMarketplaceFilterTagAllValues) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeLocationFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesRange) {
                      return (
                        <div key={item.id} className={`${styles.rangeSection} section`}>
                          <Range
                            entity={{ item: (item as IMarketplaceFilterRange) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeLocationFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    return null;
                  }) }
                </>
              </ReactSortable>
            )}
          </div>
          <div className={styles.primarySelectionTags}>
            <Box fontSize="24px" mb={8} fontWeight={700}>
              { translations.primarySelectionTags }
            </Box>
            {primarySectionFilters?.primarySection.tag && (
              <>
                <div className="section">
                  <TagFilter
                    entity={{ item: primarySectionFilters?.primarySection.tag }}
                    lang={marketplace?.language || ''}
                    useDrag={false}
                    useTrash={false}
                  />
                </div>
                <div className={styles.actions}>
                  <ActionButtons
                    buttons={primarySelectionTags}
                    onClick={primarySelectionTagsOnClick}
                  />
                </div>
                <Box display="flex" flexDirection="column" gap={8}>
                  <ReactSortable
                    animation={150}
                    handle=".drag-handle"
                    list={primarySectionFilters?.primarySection.tag.selectedProperties.map((entity) => ({ ...entity, chosen: false }))}
                    setList={(newList, sortable: Sortable | null, store: Store) => {
                      if (store && store.dragging && store.dragging.props
                        && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                        const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                        setPrimarySectionFilters({
                          ...primarySectionFilters,
                          primarySection: {
                            ...primarySectionFilters.primarySection,
                            selections: [...primarySectionFilters?.primarySection.selections],
                            tag: {
                              ...primarySectionFilters?.primarySection.tag,
                              selectedProperties: orderedList,
                            },
                          },
                          secondaries: [...primarySectionFilters?.secondaries],
                        });
                      }
                    }}
                  >
                    {primarySectionFilters?.primarySection.tag.selectedProperties.map((property, index) => (
                      <div key={property.id}>
                        <SubGroupWrapper name="Primary tag filter (selected values)" onTrash={() => removePrimarySectionTagByIndex(index)}>
                          <PrimaryTagFilter
                            marketplace={marketplace}
                            entity={{ item: property }}
                            lang={marketplace?.language || ''}
                            isNeedToChange={isNeedToBeUpdated(property)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </SubGroupWrapper>
                      </div>
                    )) }
                  </ReactSortable>
                </Box>
              </>
            ) }
          </div>
          <div className="secondary-filters">
            <Box fontSize="24px" mb={8} fontWeight={700}>
              { translations.secondaryFilters }
            </Box>
            <div className={styles.actions}>
              <ActionButtons
                buttons={secondaryFilters}
                onClick={secondarySelectionFiltersOnClick}
              />
            </div>
            {primarySectionFilters?.secondaries && (
              <ReactSortable
                animation={150}
                handle=".drag-handle"
                list={primarySectionFilters.secondaries.map((entity) => ({ ...entity, chosen: false }))}
                setList={(newList, sortable: Sortable | null, store: Store) => {
                  if (store && store.dragging && store.dragging.props
                    && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                    const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                    setPrimarySectionFilters({
                      ...primarySectionFilters,
                      secondaries: [...orderedList],
                    });
                  }
                }}
              >
                <>
                  { primarySectionFilters.secondaries.map((item, index) => {
                    if (item.type === IMarketplaceFilterType.GroupingFilter) {
                      return (
                        <div key={item.id} className={`${styles.locationSection} section`}>
                          <LocationGroup
                            entity={{ item }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeSecondaryFieldByIndex(index)}
                          />
                          <div className={styles.locationActions}>
                            <LocationButtons onClick={(type) => secondaryLocationButtonOnClick(type, index)} />
                          </div>
                          <Box display="flex" flexDirection="column" gap={8}>
                            <ReactSortable
                              animation={150}
                              handle=".drag-handle"
                              list={(item as IMarketplaceFilterLocation).filters.map((entity) => ({
                                ...entity,
                                chosen: false,
                              }))}
                              setList={(newList, sortable: Sortable | null, store: Store) => {
                                if (store && store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                                  const temp = { ...item, filters: newList.map((listItem, listItemIndex) => ({ ...listItem, sortOrder: listItemIndex })) };
                                  const tempIndex = primarySectionFilters?.secondaries.findIndex((section) => section.id === item.id);
                                  primarySectionFilters?.secondaries.splice(tempIndex, 1, temp);
                                  setPrimarySectionFilters({
                                    ...primarySectionFilters,
                                    secondaries: [...primarySectionFilters?.secondaries],
                                  });
                                }
                              }}
                            >
                              { (item as IMarketplaceFilterLocation).filters.map((filter, subIndex) => {
                                return (
                                  <div key={filter.id}>
                                    { !filter.subFilters.length && filter.type === IMarketplaceFilterType.NestedAllValuesTag && (
                                      <SubGroupWrapper
                                        name="Location: Elements list"
                                        onTrash={() => removeSecondaryLocationSubFieldByIndex(index, subIndex)}
                                      >
                                        <LocationSubGroupElements
                                          entity={{ item: filter }}
                                          lang={marketplace?.language || ''}
                                          locationOptions={locationMarketplacePropertyOptions}
                                        />
                                      </SubGroupWrapper>
                                    ) }
                                    { !!filter.subFilters.length && (filter?.subType === LocationButtonsEnum.locationGroup || filter.type === IMarketplaceFilterType.NestedAllValuesTag) && (
                                      <SubGroupWrapper
                                        name="Location: Location group"
                                        onTrash={() => removeSecondaryLocationSubFieldByIndex(index, subIndex)}
                                      >
                                        <LocationSubGroupLocationGroup
                                          entity={{ item: filter }}
                                          lang={marketplace?.language || ''}
                                          locationOptions={locationMarketplacePropertyOptions}
                                        />
                                      </SubGroupWrapper>
                                    ) }
                                  </div>
                                );
                              }) }
                            </ReactSortable>
                          </Box>
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesTag) {
                      return (
                        <div key={item.id} className={`${styles.tagSection} section`}>
                          <TagAllValues
                            entity={{ item: (item as IMarketplaceFilterTagAllValues) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeSecondaryFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesRange) {
                      return (
                        <div key={item.id} className={`${styles.rangeSection} section`}>
                          <Range
                            entity={{ item: (item as IMarketplaceFilterRange) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeSecondaryFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.AllValuesCheckbox) {
                      return (
                        <div key={item.id} className={`${styles.checkboxSection} section`}>
                          <Checkbox
                            entity={{ item: (item as IMarketplaceFilterTagAllValues) }}
                            lang={marketplace?.language || ''}
                            onTrash={() => removeSecondaryFieldByIndex(index)}
                            propertyOptions={marketplacePropertyOptions}
                          />
                        </div>
                      );
                    }
                    if (item.type === IMarketplaceFilterType.SelectedValuesTag) {
                      return (
                        <div key={item.id} className={`${styles.checkboxSection} section`}>
                          <div>
                            <TagFilter
                              entity={{ item: (item as IMarketplaceFilterTagSelectedValues) }}
                              lang={marketplace?.language || ''}
                              onTrash={() => removeSecondaryFieldByIndex(index)}
                            />
                          </div>
                          <div className={styles.actions}>
                            <ActionButtons
                              buttons={primarySelectionTags}
                              onClick={() => primarySelectedTagOnClick(index)}
                            />
                          </div>
                          <Box display="flex" flexDirection="column" gap={8}>
                            <ReactSortable
                              animation={150}
                              handle=".drag-handle"
                              list={(item as IMarketplaceFilterTagSelectedValues).selectedProperties.map((entity) => ({
                                ...entity,
                                chosen: false,
                              }))}
                              setList={(newList, sortable: Sortable | null, store: Store) => {
                                if (store && store.dragging && store.dragging.props
                                  && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                                  const orderedList = newList.map((listItem, listItemIndex) => ({
                                    ...listItem,
                                    sortOrder: listItemIndex,
                                  }));
                                  const tmp = { ...(item as IMarketplaceFilterTagSelectedValues), selectedProperties: orderedList };
                                  const tempIndex = primarySectionFilters?.secondaries.findIndex((section) => section.id === item.id);
                                  primarySectionFilters?.secondaries.splice(tempIndex, 1, tmp);
                                  setPrimarySectionFilters({
                                    ...primarySectionFilters,
                                    secondaries: [...primarySectionFilters?.secondaries],
                                  });
                                }
                              }}
                            >
                              { (item as IMarketplaceFilterTagSelectedValues).selectedProperties.map((property, propertyIndex) => (
                                <div key={property.id}>
                                  <SubGroupWrapper
                                    name="Primary tag filter (selected values)"
                                    onTrash={() => removePrimarySelectedTagByIndex(index, propertyIndex)}
                                  >
                                    <PrimaryTagFilter
                                      marketplace={marketplace}
                                      entity={{ item: property }}
                                      lang={marketplace?.language || ''}
                                      isNeedToChange={false}
                                      propertyOptions={marketplacePropertyOptions}
                                    />
                                  </SubGroupWrapper>
                                </div>
                              )) }
                            </ReactSortable>
                          </Box>
                        </div>
                      );
                    }
                    return null;
                  }) }
                </>
              </ReactSortable>
            ) }
          </div>
        </Box>
      </form>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
          { error }
        </Alert>
      </Snackbar>
    </Box>
  );
};
