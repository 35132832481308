import React from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Box, OutlinedInputProps, TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

interface IBoxFormTextField {
  label?: string,
  name?: string,
  value?: string,
  defaultValue?: string,
  inputRef?: React.Ref<any>,
  placeholder?: string,
  startAdornment?: React.ReactNode,
  inputStyle?: { [key: string]: string },
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  props?: TextFieldProps | OutlinedInputProps,
}

export const BoxFormTextField: React.FC<IBoxFormTextField> = ({
  label,
  name,
  value,
  defaultValue,
  inputRef,
  startAdornment,
  placeholder,
  onChange,
  ...props
}) => {
  return (
    <Box mb={6} width="100%" display="flex" flexDirection="column">
      <Box mb={2}>
        { label && (label) }
      </Box>
      <Box className="text-field-wrapper">
        { !startAdornment && (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            inputRef={inputRef}
            onChange={onChange}
            {...props}
          />
        ) }
        { startAdornment && (
          <OutlinedInput
            fullWidth
            startAdornment={startAdornment}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={name}
            inputRef={inputRef}
            onChange={onChange}
            {...props as OutlinedInputProps}
          />
        ) }
      </Box>
    </Box>
  );
};
