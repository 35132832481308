import { IAction } from 'src/typings';
import {
  IMarketplace, IMarketplaceLocationRequestFilter,
  IMarketplaceRangeRequestFilter,
  IMarketplaceRequestFilter,
} from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceFilterResponse, IMarketplaceFilterResponseFilter } from 'src/typings/marketplace';
import { IComplex } from 'src/typings/complexes';
import { IPlacement } from 'src/store/placement/reducer';
import { ActionTypes } from './actions';

export interface IMarketplaceReducer {
  currentMarketplace: IMarketplace | undefined;
  language: string | undefined;
  filtersInitial: IMarketplaceFilterResponseFilter[];
  filters: IMarketplaceFilterResponseFilter[];
  filterWhereConditions: IMarketplaceRequestFilter[];
  activeFilter: string;
  complexesTile: IComplex[];
  placementsTile: IPlacement[];
}

export const initialState: IMarketplaceReducer = {
  currentMarketplace: undefined,
  language: undefined,
  filtersInitial: [],
  filters: [],
  filterWhereConditions: [],
  activeFilter: '',
  complexesTile: [],
  placementsTile: [],
};

export const marketplaceReducer = (
  state = initialState,
  { type, payload }: IAction,
): IMarketplaceReducer => {
  switch (type) {
    case ActionTypes.SET_MARKETPLACE: {
      return {
        ...state,
        currentMarketplace: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS_INITIAL: {
      return {
        ...state,
        filtersInitial: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS: {
      return {
        ...state,
        filterWhereConditions: [],
      };
    }
    case ActionTypes.SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS: {
      let data = [...state.filterWhereConditions];
      data = data.filter((item) => !payload.includes(item.propertyName));
      return {
        ...state,
        filterWhereConditions: data,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS: {
      const find = [...state.filterWhereConditions]
        .filter((item) => {
          const foundCondition = (payload as IMarketplaceRequestFilter[])
            .find((p) => {
              if ('equals' in p) {
                return JSON.stringify(p) === JSON.stringify(item);
              }
              if (('min' && 'max') in p) {
                return p.propertyName === item.propertyName;
              }
              return false;
            });
          if (!foundCondition) return false;
          const isEqual = JSON.stringify(item) === JSON.stringify(foundCondition);
          const isEqualPropertyName = JSON.stringify(item.propertyName) === JSON.stringify(foundCondition.propertyName);
          const isRangePropertyName = JSON.stringify(item.propertyName) === JSON.stringify(foundCondition.propertyName)
              && (item as IMarketplaceRangeRequestFilter).min !== undefined
              && (item as IMarketplaceRangeRequestFilter).max !== undefined
              && item as IMarketplaceRangeRequestFilter;
          return isEqual
              || (isEqual && isEqualPropertyName)
              || (!isEqual && isRangePropertyName);
        });

      let data = [...state.filterWhereConditions];
      const deleteItem = (item: IMarketplaceRequestFilter[]) => {
        const temp = [...state.filterWhereConditions];
        item.forEach((request) => {
          const index = temp.indexOf(request);
          if ((request as IMarketplaceRangeRequestFilter).min !== undefined && (request as IMarketplaceRangeRequestFilter).max !== undefined) {
            temp.splice(index, 1, payload[0]);
          } else {
            temp.splice(index, 1);
          }
        });
        return temp;
      };
      if (find.length) {
        data = deleteItem(find);
      } else {
        data = [...state.filterWhereConditions, ...payload];
      }
      return {
        ...state,
        filterWhereConditions: data,
      };
    }
    case ActionTypes.SET_MARKETPLACE_COMPLEXES_TILE: {
      return {
        ...state,
        complexesTile: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_PLACEMENTS_TILE: {
      return {
        ...state,
        placementsTile: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: payload,
      };
    }

    default:
      return state;
  }
};
