import { useMemo, useState } from 'react';
import { IDeveloper, IDevelopmentSpecification, ISpecificationProp } from 'src/typings/complexes';
import { getDeveloperSpecifications } from 'src/api';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import {
  ISpecsDefaultValues,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';

export const useGetDeveloperSpecifications = (developer: IDeveloper | undefined) => {
  const [specifications, setSpecifications] = useState<IDevelopmentSpecification[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchSpecifications = () => {
    if (developer?.id) {
      setIsLoading(true);
      getDeveloperSpecifications(jwt, developer?.id)
        .then((res: {data: {'hydra:member': IDevelopmentSpecification[]}}) => {
          const { data } = res;
          setSpecifications(data['hydra:member']);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const unselectedOption = { label: 'not selected', value: undefined };
  const mapping = (item: ISpecificationProp) => {
    if (item.type === 'CompletionDate' as DeveloperPropertyType.completionDates) {
      return { label: `${item.prefix[developer?.language || '']} ${item.name[developer?.language || '']} ${item.suffix[developer?.language || '']}`, value: item };
    }
    return { label: item.name[developer?.language || ''], value: item };
  };

  const purchaseTermsOptions: ISpecsDefaultValues[] = useMemo(() => {
    let result: any = [];
    if (specifications?.length) {
      result = [...specifications?.[0].purchaseTerms.map(mapping) || []];
    }
    return result;
  }, [specifications, developer]);

  const propertyClassesOptions: ISpecsDefaultValues[] = useMemo(() => {
    let result: any = [];
    if (specifications?.length) {
      result = [unselectedOption, ...specifications?.[0].propertyClasses.map(mapping)];
    }
    return result;
  }, [specifications, developer]);

  const salesStatusesOptions: ISpecsDefaultValues[] = useMemo(() => {
    let result: any = [];
    if (specifications?.length) {
      result = [unselectedOption, ...specifications?.[0].salesStatuses.map(mapping) || []];
    }
    return result;
  }, [specifications, developer]);

  const constructionPhasesOptions: ISpecsDefaultValues[] = useMemo(() => {
    let result: any = [];
    if (specifications?.length) {
      result = [unselectedOption, ...specifications?.[0].constructionPhases.map(mapping) || []];
    }
    return result;
  }, [specifications, developer]);

  const completionDatesOptions: ISpecsDefaultValues[] = useMemo(() => {
    let result: any = [];
    if (specifications?.length) {
      result = [unselectedOption, ...specifications?.[0].completionDates.map(mapping) || []];
    }
    return result;
  }, [specifications, developer]);

  return {
    specifications,
    isLoading,
    purchaseTermsOptions,
    propertyClassesOptions,
    salesStatusesOptions,
    constructionPhasesOptions,
    completionDatesOptions,
    fetchSpecifications,
  };
};
