import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import {
  IMarketplaceFilterTagAllValues,
} from 'src/components/profileComponents/Marketplace/types';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getMarketplaceActiveFilter, getMarketplaceFilters } from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

interface Props {
  selection?: IMarketplaceFilterTagAllValues,
  setIsPopupOpen?: (value: null | boolean) => void,
  setCallToActionButtons?: (value: any) => void,
  closePopup?: () => void,
  customClass?: string
  showActions?: boolean,
  onApply?: () => void,
}
export const TypeFilterPopup: React.FC<Props> = ({
  selection,
  setIsPopupOpen,
  setCallToActionButtons,
  closePopup,
  customClass,
  showActions = true,
  onApply,
}) => {
  const styles = useStyles();
  const {
    getMarketplaceInitialFilter, marketplaceFilters, setWhereConditions, activeFilter, getFilterEqualsActiveData, resetFilterWhereConditions,
  } = useMarketPlaceFilter();
  const popupRef = useRef(null);
  const [options, setOptions] = useState<IMarketplaceFilterResponseValue[] | undefined>();
  const [activeButtons, setActiveButtons] = useState<string[]>([]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      setOptions(getMarketplaceInitialFilter(selection.property.systemName)?.values);
    }
  }, [selection]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getFilterEqualsActiveData(selection?.property?.systemName);
      setActiveButtons(value);
      if (setCallToActionButtons) {
        setCallToActionButtons(value);
      }
    }
  }, [selection, marketplaceFilters]);

  const toggleButton = (buttonLabel: IMarketplaceFilterResponseValue) => {
    if (selection?.property?.systemName) {
      setWhereConditions([{
        propertyName: selection?.property?.systemName,
        equals: buttonLabel.value,
      }]);
    }
  };

  const handleReset = () => {
    if (selection?.property?.systemName) {
      resetFilterWhereConditions([selection?.property.systemName]);
    }
  };

  const handleApply = () => {
    if (setIsPopupOpen) setIsPopupOpen(null);
    if (closePopup) closePopup();
  };

  const isActive = useCallback((option: IMarketplaceFilterResponseValue) => {
    return activeButtons.find((item) => item === option.value);
  }, [activeButtons]);

  return (
    <Box
      className={`${styles.popup} ${customClass || ''} popup`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="lContent">
        { options && options.map((option) => (
          <button
            key={option.value}
            className={classnames('lBtn', isActive(option) ? 'active' : '')}
            onClick={(e) => {
              e.stopPropagation();
              toggleButton(option);
            }}
            type="button"
          >
            { option.value }
            <span className={styles.counter}>
              (
              { option.unitCount }
              )
            </span>
          </button>
        )) }
      </div>

      { showActions && (
      <FilterActions
        onReset={handleReset}
        onApply={handleApply}
      />
      ) }
    </Box>
  );
};
