import React, { ReactNode } from 'react';
import { PopupStateContext } from './context';

interface Props {
  value: null | string,
  children: ReactNode,
}

export const MarketplaceFilterPopUpProvider = ({ value, children }: Props) => {
  return (
    <PopupStateContext.Provider value={value}>
      { children }
    </PopupStateContext.Provider>
  );
};
