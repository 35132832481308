import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { pathList } from 'src/containers/Profile/routeList';
import { useParams } from 'react-router-dom';
import { useMarketplaces } from 'src/hooks/marketplace';
import { Loader } from 'src/components/Loader';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import {
  IUiComponent,
  uiComponents,
} from 'src/components/profileComponents/Marketplace/components/Ui-components/constans';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import { IOSSwitch } from 'src/components/SwitchIos';
import { UploadSection } from 'src/components/profileComponents/UploadSection';
import { readURL } from 'src/helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IFileUri } from 'src/typings/complexes';
import { useStyles } from './styles';

const schema = yup.object().shape({
  marketplaceLogo: yup.string().required(translations.requiredField),
});

export const UiComponents: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { getMarketplace } = useMarketplaces();
  const styles = useStyles();
  const marketplace = getMarketplace(Number(id));
  const [uiComponentsOptions, setUiComponentsOptions] = useState<IUiComponent[]>(uiComponents);
  const [marketplaceLogoFile, setMarketplaceLogoFile] = useState<IFileUri | undefined>();
  const [marketplaceLogo, setMarketplaceLogo] = useState<string | null>(null);

  const {
    register, handleSubmit, setValue, reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleUiComponent = (value: boolean, uiComponentIndex: number) => {
    const items = [...uiComponentsOptions];
    items[uiComponentIndex].isActive = value;
    setUiComponentsOptions(items);
  };

  const _onUpload = useCallback(async (file: File, response: any) => {
    if (file && response.data[0]) {
      setMarketplaceLogoFile(response.data[0]);
      const base64: string = await readURL(file);
      if (base64) {
        setMarketplaceLogo(base64);
      }
    }
  }, []);

  const crumbs: ICrumbs[] = [
    {
      name: translations.marketplace,
      url: `/profile/${pathList.marketplace}`,
    },
    {
      name: `${marketplace?.name[marketplace?.language]} set-up`,
      url: `/profile/${pathList.marketplace}/${marketplace?.id}`,
    },
    {
      name: 'UI components display',
    },
  ];

  if (!marketplace) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {translations.marketplaceComponents}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box fontSize="24px" mb={3} fontWeight={700}>
          { translations.projectsUnitToggle }
        </Box>
        <Box mb={9}>
          <ReactSortable<IUiComponent>
            className={styles.uiComponentsWrapper}
            animation={150}
            handle=".drag-handle"
            list={uiComponents.map((entity) => ({ ...entity, chosen: false }))}
            setList={(newList, sortable: Sortable | null, store: Store) => {
              if (store && store.dragging && store.dragging.props
                && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                setUiComponentsOptions(orderedList);
              }
            }}
          >
            { uiComponentsOptions.map((item, index) => (
              <Box key={item.id} className={styles.optionWrapper}>
                <div className={styles.optionSwitch}>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={(e: any) => handleUiComponent(e.target.checked, index)}
                    name="checkedInfrastructure"
                  />
                </div>
                <div className={styles.optionLabel}>
                  { item.name }
                </div>
                <div className={styles.optionActions}><Drag /></div>
              </Box>
            )) }
          </ReactSortable>
        </Box>
        <Box fontSize="24px" mb={3} fontWeight={700}>
          { translations.headerSettings }
        </Box>
        <div className={styles.settingsContainer}>
          <div className={styles.headerSettings}>
            <div className={styles.headerSwitchWrapper}>
              <div className={styles.headerSwitch}>
                <IOSSwitch />
              </div>
              <div className={styles.headerSwitchText}>
                { translations.marketplaceLogo }
              </div>
            </div>
            <div className={styles.headerBody}>
              <UploadSection
                image={marketplaceLogo}
                onUpload={(file, response) => {
                  _onUpload(file, response);
                }}
                onMoveToTrash={() => setMarketplaceLogo(null)}
                register={register}
                registerName="marketplaceLogo"
              />
            </div>
          </div>
          <Box width="434px">
            <div className={styles.headerSwitchWrapper}>
              <div className={styles.headerSwitch}>
                <IOSSwitch />
              </div>
              <div className={styles.headerSwitchText}>
                { translations.headerTitle }
              </div>
            </div>
            <div className={styles.headerBody}>
              <div className={styles.headerTitle} />
            </div>
          </Box>
          <div className={styles.headerSettings}>
            <div className={styles.headerSwitchWrapper}>
              <div className={styles.headerSwitch}>
                <IOSSwitch />
              </div>
              <div className={styles.headerSwitchText}>
                { translations.favourites }
              </div>
            </div>
            <div className={styles.headerBody}>
              <UploadSection
                image={marketplaceLogo}
                onUpload={(file, response) => {
                  _onUpload(file, response);
                }}
                onMoveToTrash={() => setMarketplaceLogo(null)}
                register={register}
                registerName="marketplaceLogo"
              />
            </div>
          </div>
          <div className={styles.headerSettings}>
            <div className={styles.headerSwitchWrapper}>
              <div className={styles.headerSwitch}>
                <IOSSwitch />
              </div>
              <div className={styles.headerSwitchText}>
                { translations.menu }
              </div>
            </div>
            <div className={styles.headerBody}>
              <UploadSection
                image={marketplaceLogo}
                onUpload={(file, response) => {
                  _onUpload(file, response);
                }}
                onMoveToTrash={() => setMarketplaceLogo(null)}
                register={register}
                registerName="marketplaceLogo"
              />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};
