import { postMarketplaceFilter } from 'src/api/marketplace';
import { useCallback, useEffect, useState } from 'react';
import { useGetAuthData } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentMarketplace, getMarketplaceActiveFilter, getMarketplaceFilters,
  getMarketplaceInitialFilters, getMarketplaceWhereConditions,
} from 'src/store/marketplace/selectors';
import { IMarketplaceFilterResponseFilter } from 'src/typings/marketplace';
import { Actions } from 'src/store/marketplace/actions';
import {
  IMarketplaceLocationRequestFilter, IMarketplaceProperty, IMarketplaceRangeRequestFilter,
  IMarketplaceRequestFilter,
} from 'src/components/profileComponents/Marketplace/types';

export const useMarketPlaceFilter = () => {
  const marketplace = useSelector(getCurrentMarketplace);
  const marketplaceInitialFilters = useSelector(getMarketplaceInitialFilters);
  const marketplaceFilters = useSelector(getMarketplaceFilters);
  const whereConditions = useSelector(getMarketplaceWhereConditions);
  const activeFilter = useSelector(getMarketplaceActiveFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchMarketplacesFilters = useCallback(() => {
    if (marketplace) {
      setIsLoading(true);
      postMarketplaceFilter(jwt, {
        language: marketplace.language,
        marketplaceId: marketplace.id,
      })
        .then((res) => {
          // const { data } = res;
          // setMarketplaces(data['hydra:member']);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [marketplace]);

  const getMarketplaceInitialFilter = useCallback((searchPropertyName: string) => {
    let result: IMarketplaceFilterResponseFilter | undefined;
    if (marketplaceInitialFilters) {
      result = marketplaceInitialFilters.find((filter) => filter.propertyName === searchPropertyName);
    }
    return result;
  }, [marketplaceInitialFilters]);

  const getMarketplaceFilter = useCallback((searchPropertyName: string) => {
    let result: IMarketplaceFilterResponseFilter | undefined;
    if (marketplaceFilters) {
      result = marketplaceFilters.find((filter) => filter.propertyName === searchPropertyName);
    }
    return result;
  }, [marketplaceFilters]);

  const getFilterEqualsActiveData = useCallback((propertyName: string) => {
    let result: string[] = [];
    if (whereConditions) {
      result = whereConditions.filter((filter) => filter.propertyName === propertyName)
        .map((filter) => {
          return (filter as IMarketplaceLocationRequestFilter).equals;
        });
    }
    return result;
  }, [whereConditions]);

  const getFilterRangeActiveData = useCallback((propertyName: string) => {
    let result: number[] = [];
    if (whereConditions) {
      result = whereConditions.filter((filter) => filter.propertyName === propertyName)
        .map((filter) => {
          return [(filter as IMarketplaceRangeRequestFilter).min, (filter as IMarketplaceRangeRequestFilter).max];
        })[0];
    }
    return result;
  }, [whereConditions]);

  const setWhereConditions = useCallback((condition: IMarketplaceRequestFilter[]) => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceWhereConditions(condition));
      if (condition?.[0]?.propertyName) {
        dispatch(Actions.setMarketplaceActiveFilter(condition[0].propertyName));
      }
    }
  }, [whereConditions]);

  const resetAllFilterWhereConditions = useCallback(() => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceResetAllWhereConditions());
    }
  }, [whereConditions]);

  const resetFilterWhereConditions = useCallback((propertyName: string[]) => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceResetWhereConditions(propertyName));
    }
  }, [whereConditions]);

  return {
    isLoading,
    fetchMarketplacesFilters,
    marketplaceInitialFilters,
    marketplaceFilters,
    activeFilter,
    whereConditions,
    getMarketplaceInitialFilter,
    getMarketplaceFilter,
    getFilterEqualsActiveData,
    getFilterRangeActiveData,
    setWhereConditions,
    resetFilterWhereConditions,
    resetAllFilterWhereConditions,
  };
};
