import React from 'react';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import { translations } from 'src/constants/translations';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import {
  accordionStateOptions,
  AccordionStateType, IFieldWithTranslation,
  IMarketplaceFilterTagAllValues,
  IMarketplaceProperty,
  sortOrderOptions,
  sortOrderOptionsMap,
  sortOrderOptionsNotProjectName,
  SortOrderType,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  useStyles,
} from './styles';

interface ITag {
  entity: {item: IMarketplaceFilterTagAllValues},
  lang: string
  propertyOptions: IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]
  onTrash: () => void,
}

export const TagAllValues: React.FC<ITag> = ({
  entity, lang, propertyOptions, onTrash,
}) => {
  const styles = useStyles();
  const isProjectName = entity.item.property?.systemName === 'projectName';
  return (
    <>
      <GroupWrapper name="Tag(all values)" startIcon={<LabelOutlinedIcon />} onTrash={onTrash}>
        <div className={styles.tagWrapper}>
          <BoxFormTextField
            label={translations.name}
            name="name"
            defaultValue={entity.item.name?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterTagAllValues>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.callToAction}
            name="callToAction"
            defaultValue={entity.item.callToActionText?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterTagAllValues>({ propLink: entity.item, propName: 'callToActionText' }, event.target.value, lang);
            }}
          />
          <BoxFormAutocomplete
            label={translations.chooseProperty}
            name="property"
            defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
            onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
              const tmp = entity;
              tmp.item.property = event.value;
            }}
            options={propertyOptions}
          />
          <BoxFormAutocomplete
            label={translations.sortOrder}
            name="sortOrder"
            defaultValue={
              entity.item.propertyValuesOrder
                ? {
                  label: sortOrderOptionsMap[entity.item.propertyValuesOrder],
                  value: entity.item.propertyValuesOrder,
                }
                : undefined
            }
            options={isProjectName ? sortOrderOptions : sortOrderOptionsNotProjectName}
            onChange={(event: IDevelopmentSpecificationPropChange<SortOrderType>) => {
              const tmp = entity;
              tmp.item.propertyValuesOrder = event.value;
            }}
          />
          <BoxFormAutocomplete
            label={translations.accordionState}
            name="accordionState"
            defaultValue={{ label: entity.item.accordionState, value: entity.item.accordionState }}
            options={accordionStateOptions}
            onChange={(event: {label: string, value: AccordionStateType}) => {
              const tmp = entity;
              tmp.item.accordionState = event.value;
            }}
          />
        </div>
      </GroupWrapper>
    </>
  );
};
