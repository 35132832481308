import React from 'react';

import {
  AppFullScreenContainer,
  FilterMap,
} from 'src/components';
import { locals } from 'src/constants/locals';
import {
  useSetDocumentTitle,
  useTranslations,
  useDisablePinchZoomEffect,
  useIsMobile, useBuildType,
} from 'src/hooks';
import { Marketplace } from 'src/components/Marketplace';
import { FilterDesktop } from '../../components/FilterProperty/components';

export const MainPage: React.FC = () => {
  const { getTranslations } = useTranslations();
  const { isMobileScreen } = useIsMobile();
  const { isMarketplace } = useBuildType();
  useDisablePinchZoomEffect();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitleMainPage));

  return (
    <>
      { !isMarketplace && (
      <AppFullScreenContainer>
        <>
          {!isMobileScreen && <FilterDesktop />}
          <FilterMap />
        </>
      </AppFullScreenContainer>
      ) }
      { isMarketplace && (
        <Marketplace />
      ) }
    </>
  );
};
