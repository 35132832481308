import React, {
  useContext, useEffect, useState,
} from 'react';
import Box from '@mui/material/Box';
import { PopupStateContext } from 'src/components/Marketplace/components/MarketplaceFilterHeader/context';
import { PriceFilterPopup } from 'src/components/Marketplace/components/PriceFilter/PriceFilterPopup';
import {
  IMarketplaceFilterRange,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import { getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

interface Props {
  selection: IMarketplaceFilterRange,
  setIsPopupOpen: (state: string | null) => void,
  closePopup: () => void,
  showCurrencyToggle: boolean,
}

export const PriceFilter: React.FC<Props> = ({
  selection, setIsPopupOpen, closePopup, showCurrencyToggle,
}) => {
  const styles = useStyles();
  const [currency, setCurrency] = useState('грн');
  const lang = useSelector(getMarketplaceLanguage);
  const [value, setValue] = useState([0, 0]);
  const [isPopupOpen, setIsLocalPopupOpen] = useState<null | boolean>(false);
  const filterType = 'PriceFilter';

  const openPopupType = useContext(PopupStateContext);

  useEffect(() => {
    if (openPopupType !== filterType && openPopupType !== null) {
      setIsLocalPopupOpen(false);
    }
  }, [openPopupType, setIsLocalPopupOpen]);

  const togglePopup = () => {
    const newState = !isPopupOpen;
    setIsLocalPopupOpen(newState);
    setIsPopupOpen(newState ? 'PriceFilter' : null);
  };

  const handleCurrencyChange = (event: React.MouseEvent<HTMLButtonElement>, newCurrency: string) => {
    event.stopPropagation();
    setCurrency(newCurrency);
  };

  return (
    <Box
      className={`${styles.filterItem} ${isPopupOpen ? 'show' : ' '}`}
      onClick={(event) => { event.stopPropagation(); togglePopup(); }}
    >
      <div
        className={styles.header}
      >
        {lang && selection.name?.[lang] && (
          <h3 className={styles.title}>{ selection.name[lang] }</h3>
        ) }
        {showCurrencyToggle && (
          <div className={styles.currencyToggle}>
            <button
              className={`${styles.currencyButton} ${
                currency === 'грн' ? styles.activeCurrency : ''
              }`}
              onClick={(event) => handleCurrencyChange(event, 'грн')}
              type="button"
            >
              грн
            </button>
            <button
              className={`${styles.currencyButton} ${
                currency === '$' ? styles.activeCurrency : ''
              }`}
              onClick={(event) => handleCurrencyChange(event, '$')}
              type="button"
            >
              $
            </button>
          </div>
        ) }
      </div>

      <span className={styles.popupTrigger} onClick={togglePopup} role="presentation">
        { `від ${value[0].toLocaleString()} — до ${value[1].toLocaleString()}` }
      </span>

      <PriceFilterPopup
        selection={selection}
        value={value}
        setValue={setValue}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsLocalPopupOpen}
        closePopup={closePopup}
        labelFrom="від"
        labelTo="до"
      />
    </Box>
  );
};
