export interface IUiComponent {
  id: number,
  name: string,
  isActive: boolean,
  sortOrder: number,
}

export const uiComponents: IUiComponent[] = [
  {
    id: 1,
    name: 'Projects view',
    isActive: false,
    sortOrder: 1,
  },
  {
    id: 2,
    name: 'Units view',
    isActive: false,
    sortOrder: 1,
  },
];
