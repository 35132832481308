import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  btn: {
    backgroundColor: '#F9E8FF',
    padding: '10px 16px',
    borderRadius: '20px',
    height: '40px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.6px',
  },
}));
