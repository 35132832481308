import React from 'react';
import { MarketplaceHeader } from 'src/components/Marketplace/components/MarketplaceHeader';
import { MarketplaceFilterHeader } from 'src/components/Marketplace/components/MarketplaceFilterHeader';
import { MarketplaceFilterOptions } from 'src/components/Marketplace/components/MarketplaceFilterOptions';
import { MarketplaceFilterResults } from 'src/components/Marketplace/components/MarketplaceFilterResults';
import { useStyles } from './styles';

export const Marketplace: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <MarketplaceHeader />
      <MarketplaceFilterHeader />
      <MarketplaceFilterOptions />
      <MarketplaceFilterResults />
    </div>
  );
};
