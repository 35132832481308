import React from 'react';
import { useSelector } from 'react-redux';
import { getMarketplaceComplexesTile, getMarketplacePlacementsTile } from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

export const NavigationTabs: React.FC<any> = ({ activeTab, onTabClick }) => {
  const styles = useStyles();
  const complexes = useSelector(getMarketplaceComplexesTile);
  const placements = useSelector(getMarketplacePlacementsTile);

  return (
    <div className={[styles.headerNav, 'headerNavTop'].join(' ')}>
      <nav className={styles.navigation}>
        <button
          className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`}
          onClick={() => onTabClick(0)}
          type="button"
        >
          {`${complexes?.length} проєкти`}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`}
          onClick={() => onTabClick(1)}
          type="button"
        >
          {`${placements?.length} юніти`}
        </button>
      </nav>
    </div>
  );
};
